/* eslint-disable eqeqeq */
import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { config } from "_helpers/config";
import _ from "lodash";
import DatePicker from "react-datepicker";
import { docsActions } from "_actions/docs.actions";
//import { usPhone } from "_utils/formatter";
import { toast } from "react-toastify";
//import { docsService } from "_services/docs.service";
import moment from "moment";
import { SignPad } from "Docs/Components";
import { FormNav } from "./FormNav";
import { navigate, validate } from "./formHandlers";
import { getCurrentUserInfo } from "_helpers/reduxSelector";
import checkIfDocsShouldBeLocked from "_helpers/lockDocs";

class JobDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rehire_doc: false,
      isOpenRequirement: false,
      form: {
        signed_date: moment().format("MM/DD/YYYY"),
        approved_date: moment().format("MM/DD/YYYY"),
        disclaimer_check: false,
        employee_signature: null,
      },
      errors: {},
      form_edited: false,
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleSignature = this.handleSignature.bind(this);
    this.navigate = navigate.bind(this);
    this.validate = validate.bind(this);
    this.toggleRequirement = this.toggleRequirement.bind(this);
    this.goBack = this.goBack.bind(this);
    // Refs
    this.form = React.createRef();
    this.signContainer = React.createRef();
  }

  toggleRequirement() {
    this.state.isOpenRequirement
      ? this.setState({
          isOpenRequirement: false,
        })
      : this.setState({
          isOpenRequirement: true,
        });
  }

  handleFormChange(e) {
    if (e.target && e.target.attributes["field"]) {
      let field = e.target.attributes["field"].value;
      let nextState = this.state;
      let { name, value, checked, type } = e.target;
      if (type == "checkbox") {
        _.set(nextState, field, checked);
      } else {
        _.set(nextState, field, value);
      }
      this.setState({
        ...nextState,
        errors: {
          ...this.state.errors,
          [field]: "",
        },
      });
    }
    !this.state.form_edited && this.setState({ form_edited: true });
  }

  handleSignature(field, employee_signature) {
    let nextState = this.state;
    _.set(nextState, field, employee_signature);
    this.setState({
      ...nextState,
      errors: {
        ...this.state.errors,
        [field]: "",
      },
    });
  }

  handleDateChange(field, date) {
    let nextState = this.state;
    if (date){
    _.set(nextState, field, date.format("MM/DD/YYYY"));
    }
    this.setState({
      ...nextState,
      errors: {
        [field]: "",
      },
    });
  }

  submitForm(e) {
    e.preventDefault();

    let err = this.validate();

    if (err) {
      return;
    }

    let { form, doc } = this.state;

    let data = {
      id: doc.id,
      doc_type: doc.docs_type_id,
      form,
    };

    if (this.props.adminMode) {
      data.admin_mode = this.props.adminMode;
    }
    this.props.single_doc
      ? (data.single_doc = true)
      : (data.single_doc = false);
    this.props.rehire ? (data.rehire = true) : (data.rehire = false);

    this.props.dispatch(docsActions.saveDoc(data)).then((res) => {
      if (res.success) {
        if (this.props.mobileMode && !this.props.lastPage) {
          this.props.setScreen("sidebar");
          this.navigate("next", this.props.rehire);
        } else if (this.props.lastPage) {
          if (this.props.adminMode) {
            this.props.history.push(`/docs`);
          } else {
            this.props.history.push(`/dashboard`);
          }
        } else {
          this.navigate("next", this.props.rehire);
        }
      } else {
        if (this.props.mobileMode) this.props.toggleErrorPopup(res.message);
        else toast.error(res.message);
      }
    });
  }

  // GET FORM data from api with id passed in props
  getFormData(id) {
    this.props.dispatch(docsActions.getForm(id)).then((res) => {
      if (res.success) {
        const currentState = this.state;
        let { form, user} = res.data;
        // date needs to a moment object or Datepicker breaks.
        form.signed_date = form.signed_date || currentState.form.signed_date;
        form.approved_date =
          form.approved_date || currentState.form.approved_date;

        if (!form?.printed_name) {
          form.printed_name = user?.full_name ?? ""
        }

        // spread the data coming from API into state
        this.setState({
          form: {
            ...this.state.form,
            ...form,
          },
        });
      }
    });
  }

  goBack() {
    // this.props.resetForm();
    if (this.state.form_edited) {
      this.props.toggleCloseConfirmation(true);
    } else {
      this.props.setScreen("sidebar");
    }
  }

  componentDidMount() {
    let docSlug = this.props.match.params.doc;
    let doc;
    // if (this.props.rehire) {
    //     doc = this.props.docs.userDocs.find(
    //         (doc) => doc.slug === docSlug && doc.docs_categories_id === 2,
    //     );
    // } else {
    //     doc = this.props.docs.userDocs.find((doc) => doc.slug === docSlug && doc.docs_categories_id == 1);
    // }
    if (this.props.giss) {
      if (this.props.rehire) {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id === 5
        );
      } else {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id == 4
        );
      }
    } else {
      if (this.props.rehire) {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id === 2
        );
      } else {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id == 1
        );
      }
    }
    const height = this.signContainer.clientHeight;
    const width = this.signContainer.clientWidth;
    this.setState({
      doc,
      sign: {
        height: height,
        width: width,
      },
    });
    this.getFormData(doc.id);
  }

  render() {
    let { form, errors } = this.state;
    let { adminMode, currentUserInfo} = this.props;


    const shouldLockDocs = checkIfDocsShouldBeLocked(currentUserInfo, form, adminMode)

    return (
      <div className="doc_info-rh">
        {/* Title */}
        <div className="doc-title">
          <img
            src={`${config.assetUrl}icons/doc-title-img.svg`}
            className="doc-title-img"
          />
          <span className="close-button" onClick={this.goBack}>
            <img src={`${config.assetUrl}icons/back-btn.svg`} />
          </span>
          <h3>Job Description</h3>
        </div>
        <div className="doc_info-form">
          <form
            className="info-form"
            ref={this.form}
            onChange={this.handleFormChange}
            onSubmit={this.submitForm}
            noValidate
          >
         <fieldset disabled={shouldLockDocs}>

            <div className="row form-row no_border pt-0">
              <div className="col">
                <div className="inputs-col">
                  {/*single input */}
                  <div className="inputs-head">Job Title</div>
                  <div className="inputs-items">
                    <input
                      type="text"
                      className={`${errors["form.job_title"] ? "error" : ""}`}
                      name="job_title"
                      id="job_title"
                      value="Wildland Firefighter Type II , Type I, SRB"
                      field="form.job_title"
                      placeholder="- Enter here -"
                      disabled
                    />
                    {errors["form.job_title"] && (
                      <label htmlFor="job_title" className="error_label">
                        {errors["form.job_title"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">Reports To</div>
                  <div className="inputs-items">
                    <input
                      type="text"
                      className={`${errors["form.reports_to"] ? "error" : ""}`}
                      name="reports_to"
                      id="reports_to"
                      value="Crew Captain, Crew Superintendent"
                      field="form.reports_to"
                      placeholder="- Enter here -"
                      disabled
                    />
                    {errors["form.reports_to"] && (
                      <label htmlFor="reports_to" className="error_label">
                        {errors["form.reports_to"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className="row">
              <div className="col-md-12">
                <h6 className="inputs-head">Job Purpose</h6>
                <p>
                  While using complex equipment, wildland firefighters
                  extinguish, control and contain wildfires. The job can be
                  dangerous and wildland firefighters should be in prime
                  physical condition, being able to carry loads of over 45 lbs.
                  for extended periods of time while hiking over rough terrain
                  and dealing with adverse conditions
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h6 className="inputs-head">Duties and Responsibilities</h6>
                <p>
                  Most wildland firefighter duties are related to prescribed
                  burning, wildfire suppression, and fire preparedness. Duties
                  include, but are not limited to:
                </p>
                <ol className="checkb_lists">
                  <li>
                    Serve as a firefighter or engine operator during prescribed
                    burning and wildfire suppression activities.
                  </li>
                  <li>Conduct backfire and burnout operations.</li>
                  <li>
                    Participate as a crew member during fire break preparation
                    which involves rock removal, mowing, trimming, tree and
                    brush removal.
                  </li>
                  <li>
                    Fell trees, cut and clear brush, and dig trenches to create
                    firelines, using chainsaws, shovels, axes or other hand
                    tools.
                  </li>
                  <li>
                    Establish water supplies, connect hoses, and direct water
                    onto fires.
                  </li>
                  <li>
                    Perform regular maintenance and repairs on various equipment
                    such as fire engines, chippers, chain saws, and hand tools
                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h6 className="inputs-head">Qualifications</h6>
                <p>
                  Minimum qualifications that are necessary to be considered for
                  the position include:
                </p>
                <ol className="checkb_lists">
                  <li>High school diploma/GED</li>
                  <li>18 years of age or older</li>
                  <li>
                    Basic Wildland Firefighter Course certificate: S-130, S-190,
                    L-180
                  </li>
                  <li>Work Capacity Test (Pack Test) - Arduous</li>
                  <li>IS-100.B certificate</li>
                  <li>IS-700a certificate</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h6 className="inputs-head">Working Conditions</h6>
                <p>
                  On call 24 hours a day, 7 days a week, working 12-16 hour
                  shifts with few breaks. 2-3 weeks without days off. Adverse
                  weather conditions and poor air quality. Strenuous and
                  prolonged physical labor on rough terrain. Extended periods
                  without a bed, shower, hot meals, and other comforts. While on
                  job site, employee may not have the ability to communicate
                  with family and friends.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h6 className="inputs-head">Physical Requirements</h6>
                <p>
                  The physical demands described herein are representative of
                  the abilities required to perform the essential functions of
                  the position. Reasonable accommodations may be made to enable
                  individuals to perform essential functions.
                </p>
                <ol className="checkb_lists">
                  <li>
                    You will be required to achieve an arduous rating on the
                    "Pack Test," which requires you to walk 3 miles with a 45
                    pound backpack in 45 minutes or less.
                  </li>
                  <li>
                    Physically fit and free of drug and alcohol addictions
                  </li>
                  <li>
                    While performing job, crew members are required to stand,
                    sit, walk, talk and hear, use hands to handle or operate
                    objects, tools or controls; and reach with hands and arms.
                  </li>
                  <li>
                    The crew member will be required frequently to climb or
                    balance, stoop, kneel, crouch, crawl and smell. The member
                    may be required to frequently lift and/or move up to 45 lbs
                    and occasionally move up to 175 lbs.
                  </li>
                  <li>
                    Crew member must be able to withstand long hours, arduous
                    work, live in group setting and go prolong periods of time
                    without rest.
                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h6 className="inputs-head">Equipment Required</h6>
                <p>
                  All firefighters are expected to wear protective and safety
                  equipment. FWFS will provide Personal Protective Equipment
                  (PPE). The employee will be responsible for providing personal
                  gear. See attached form (p.3) for list.
                </p>
              </div>
            </div>
            <div className="row form-row">
              <div className="col">
                <div className="disclaimers dis-notes">
                  {/*add class toggled*/}
                  <div className="inputs-head">
                    Required Equipment and Gear List
                    {/* <span className={`heading_toggle ${this.toggleRequirement ? 'open' : 'close'}`} onClick={this.toggleRequirement}>{`Open ˅`}</span> */}
                    <button
                      type="button"
                      className={`toggler_btn ${
                        this.state.isOpenRequirement ? "toggled" : ""
                      }`}
                      onClick={this.toggleRequirement}
                    ></button>
                  </div>
                  {this.state.isOpenRequirement && (
                    <Fragment>
                      <p>Section references are to the Internal Revenue Code</p>
                      <p>
                        Firestorm Provides all employees with the following:
                      </p>
                      <p>
                        <strong>PPE ( Personal Protective Equipment)</strong>
                      </p>
                      <ol className="checkb_lists">
                        <li>Helmet</li>
                        <li>Gloves ( Leather)</li>
                        <li>Safety Glasses</li>
                        <li>Ear Plugs</li>
                        <li>Nomex Pants</li>
                        <li>Nomex Shirt</li>
                        <li>Line Gear ( Fireline Pack)</li>
                        <li>Fire Shelter</li>
                        <li>Chainsaw Chaps ( If sawyer or swamper)</li>
                        <li>1- Company Uniform T-shirt</li>
                      </ol>
                      <p>
                        <strong>Employee Provided Gear (Recommended)</strong>
                      </p>
                      <ol className="checkb_lists">
                        <li>
                          Boots (8” Tall, Leather, Vibram / Lug Sole) with spare
                          laces
                        </li>
                        <li>Duffle Bag for Personal Gear</li>
                        <li>
                          Sleeping Bag (Cold Weather should be rated to 0
                          Degrees)
                        </li>
                        <li>Sleeping pad</li>
                        <li>Tent w/ Rainfly</li>
                        <li>Water bottles (must have 4-6 quarts min)</li>
                        <li>24 hr rations</li>
                        <li>Headlamp (extra batteries)</li>
                        <li>
                          First aid kit (Company cannot provide Aspirin, Tylenol
                          or other ingestible medications)
                        </li>
                        <li>Utility knife</li>
                        <li>
                          Employee Provided Gear (Recommended)
                          <ul>
                            <li>Belt (no studs)</li>
                            <li>Toiletries &amp; Personal Hygiene products</li>
                            <li>
                              T-Shirts – 100% cotton for under Nomex (3 min)
                            </li>
                            <li>Additional Company T-shirts</li>
                            <li>Bandana (for under helmet only)</li>
                            <li>Raincoat/ poncho</li>
                            <li>
                              Sweatshirt or Jacket (Company logo or plain dark –
                              no logos)
                            </li>
                            <li>Hat (Company logo or plain dark – no logos)</li>
                            <li>Socks (5 pairs)</li>
                            <li>Underwear (5 pairs)</li>
                            <li>Non-work clothes for days off</li>
                          </ul>
                        </li>
                        <li>Wallet – access to cash</li>
                        <li>Notepad/ pen</li>
                        <li>
                          Coyote Bag with 1 pair underware, 1 pair socks, 1
                          t-shirt
                        </li>
                      </ol>
                      <p className="notes">
                        Note: All Firestorm and Personal Items combined cannot
                        exceed 55lbs. All Personal Items must fit in one duffle
                        Bag.
                      </p>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
            <div className="row form-row">
              <div className="col">
                <div className="inputs-col w-50 sign-input">
                  <div className="inputs-head">
                    Printed Name<span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <input
                      type="text"
                      className={`${
                        errors["form.printed_name"] ? "error" : ""
                      }`}
                      name="printed_name"
                      id="printed_name"
                      field="form.printed_name"
                      value={form.printed_name}
                      placeholder="Print Name"
                      required
                    />
                    {errors["form.printed_name"] && (
                      <label htmlFor="printed_name" className="error_label">
                        {errors["form.printed_name"]}
                      </label>
                    )}
                  </div>
                  <div className="inputs-head">
                    Employee Signature
                    <span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <div
                      ref={(element) => {
                        this.signContainer = element;
                      }}
                      className="sign_here"
                    >
                      {this.state.sign && (
                        <Fragment>
                          <SignPad
                            id="jobDescSignPad"
                            height={this.state.sign.height}
                            width={this.state.sign.width}
                            handleSignature={this.handleSignature.bind(
                              this,
                              "form.employee_signature"
                            )}
                            signature={form.employee_signature}
                            disabled={adminMode}
                          />
                          <input
                            type="hidden"
                            value={form.employee_signature}
                            name="employee_signature"
                            field="form.employee_signature"
                            required
                          ></input>
                          {errors["form.employee_signature"] && (
                            <label
                              htmlFor="employee_signature"
                              className="error_label"
                            >
                              {errors["form.employee_signature"]}
                            </label>
                          )}
                        </Fragment>
                      )}
                    </div>
                    <div className="inputs-head">
                      Date<span className="required-star">&#x2A;</span>
                    </div>
                    <div className="inputs-items">
                      <DatePicker
                        className={`${
                          errors["form.signed_date"] ? "error" : ""
                        } w-100`}
                        name="signed_date"
                        id="signed_date"
                        required
                        selected={moment(form.signed_date)}
                        field="form.signed_date"
                        placeholder="mm/dd/yyyy"
                        onChange={this.handleDateChange.bind(
                          this,
                          "form.signed_date"
                        )}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        autoComplete="off"
                        disabled={true}
                      />
                      {errors["form.signed_date"] && (
                        <label htmlFor="signed_date" className="error_label">
                          {errors["form.signed_date"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
                <div className="inputs-col w-50 sign-input">
                  {adminMode && (
                    <Fragment>
                      <div className="inputs-head">
                        Approved By<span className="required-star">&#x2A;</span>
                      </div>
                      <div className="inputs-items">
                        <input
                          type="text"
                          className={`${
                            errors["form.approved_by"] ? "error" : ""
                          }`}
                          name="approved_by"
                          id="approved_by"
                          field="form.approved_by"
                          value={form.approved_by}
                          placeholder="Name"
                          required
                        />
                        {errors["form.approved_by"] && (
                          <label htmlFor="approved_by" className="error_label">
                            {errors["form.approved_by"]}
                          </label>
                        )}
                      </div>
                      <div className="inputs-head">
                        Approved Date
                        <span className="required-star">&#x2A;</span>
                      </div>
                      <div className="inputs-items">
                        <DatePicker
                          className={`${
                            errors["form.approved_date"] ? "error" : ""
                          } w-100`}
                          name="approved_date"
                          id="approved_date"
                          required
                          selected={moment(form.approved_date)}
                          field="form.approved_date"
                          placeholder="mm/dd/yyyy"
                          onChange={this.handleDateChange.bind(
                            this,
                            "form.approved_date"
                          )}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          autoComplete="off"
                          disabled={true}
                        />
                        {errors["form.approved_date"] && (
                          <label
                            htmlFor="approved_date"
                            className="error_label"
                          >
                            {errors["form.approved_date"]}
                          </label>
                        )}
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
              {/*row ends*/}
            </div>
            <div className="row form-row">
              <div className="col">
                <div className="disclaimers">
                  <strong className="diss">Disclaimer: </strong>You must read
                  and carefully understand the documents that you are filling
                  out and signing. These are legally binding documents and must
                  be accurate. By filling these forms out, you are agreeing to
                  our{" "}
                  <a href='https://firestormfire.com/terms-of-use/' target="blank">Terms of Use, Privacy Policy and Employee Agreements.</a>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className="row form-row last">
              <div className="col">
                <div className="inputs-col">
                  <div className="check_radio_group check_disclaimer">
                    <div className="check_radio_item">
                      <input
                        type="checkbox"
                        name="disclaimer_check"
                        id="disclaimer_check"
                        field="form.disclaimer_check"
                        checked={form.disclaimer_check}
                      />
                      <span className="checkbox_checkbox"></span>
                      <label htmlFor="disclaimer_check">
                        I have confirmed the above details are true and valid.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <FormNav
              adminMode={adminMode}
              navigate={this.navigate.bind(this)}
              disclaimer_check={form.disclaimer_check}
              mobileMode={this.props.mobileMode}
            />
            </fieldset>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { docs } = state;
  return { docs, currentUserInfo: getCurrentUserInfo(state)  };
}

let connectedJobDescription = compose(
  withRouter,
  connect(mapStateToProps)
)(JobDescription);
export { connectedJobDescription as JobDescription };
