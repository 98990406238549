export function navigate(to, rehire = this.props.rehire, giss, bind = this) {
  //common navigation function for all docs in list
  let { doc, hash } = bind.props.match.params;
  let userDocs;
  if (bind.props.giss) {
    if (rehire) {
      userDocs = bind.props.docs.userDocs.filter((doc) => doc.docs_categories_id === 5); // Rehire
    } else {
      userDocs = bind.props.docs.userDocs.filter((doc) => doc.docs_categories_id === 4); // Onboarding
    }
  } else {
    if (rehire) {
      userDocs = bind.props.docs.userDocs.filter((doc) => doc.docs_categories_id === 2); // Rehire
    } else {
      userDocs = bind.props.docs.userDocs.filter((doc) => doc.docs_categories_id === 1); // Onboarding
    }
  }

  let currentDocIndex = userDocs.findIndex((userDoc) => userDoc.slug == doc);

  let path = bind.props.match.path;

  if (hash) {
    path = path.replace(":hash", hash);
  }

  let nextDoc = {};
  let prevDoc = {};

  if (currentDocIndex < userDocs.length - 1) nextDoc = userDocs[currentDocIndex + 1];

  if (currentDocIndex > 0) prevDoc = userDocs[currentDocIndex - 1];

  if (bind.props.adminMode) {
    if (bind.props.resetForm) {
      bind.props.resetForm();
    }
  }

  switch (to) {
    case "next":
      if (nextDoc && nextDoc.id) {
        path = path.replace(":doc", nextDoc.slug);
        bind.props.history.push(`${path}?rehire=${rehire}`);
      }
      break;
    case "prev":
      if (prevDoc && prevDoc.id) {
        path = path.replace(":doc", prevDoc.slug);
        bind.props.history.push(`${path}?rehire=${rehire}`);
      }
      break;
    default:
      bind.props.history.push(to);
      break;
  }
}

// to handle navigation in functional component
export function handleNavigate(to, rehire, docs, doc, props) {
    let userDocs;
    if (props.giss) {
      userDocs = docs.userDocs.filter((d) =>
        rehire ? d.docs_categories_id === 5 : d.docs_categories_id === 4
      );
    } else {
      userDocs = docs.userDocs.filter((d) =>
        rehire ? d.docs_categories_id === 2 : d.docs_categories_id === 1
      );
    }

    const currentDocIndex = userDocs.findIndex((item) => item.slug === doc.slug);
    let path = props.match.path;

    if (props.hash) {
      path = path.replace(":hash", props.hash);
    }
    const nextDoc = userDocs[currentDocIndex + 1];
    const prevDoc = userDocs[currentDocIndex - 1];

    if (props.adminMode && props.resetForm) {
      props.resetForm();
    }
    switch (to) {
      case "next":
        if (nextDoc) {
          path = path.replace(":doc", nextDoc.slug);
          props.history.push(`${path}?rehire=${rehire}`);
        }
        break;
      case "prev":
        if (prevDoc) {
          path = path.replace(":doc", prevDoc.slug);
          props.history.push(`${path}?rehire=${rehire}`);
        }
        break;
      default:
        props.history.push(to);
        break;
    }
  };

export function validate() {
  let err = false;

  // let errorMsg = "Please enter a valid value";
  let errorMsg = "This field is required.";
  let { errors } = this.state;
  let focusEl = null;

  Object.keys(this.form.current).forEach((index) => {
    let el = this.form.current[index];
    if (el.type == "tel" && el.value) {
      let field = el.attributes["field"].value;
      let num = el.value.replace(/[^0-9]/g, "");
      if (num.length < 10) {
        errors = {
          ...errors,
          [field]: "Please provide a 10 Digit phone",
        };
        err = true;
        if (!focusEl) focusEl = el;
      }
    }
    if (el.type == "checkbox" && el.required && !el.checked) {
      let field = el.attributes["field"].value;
      errors = {
        ...errors,
        [field]: "Please agree to continue",
      };
      err = true;

      if (!focusEl) focusEl = el;
    } else if (el.required && !el.value) {
      let field = el.attributes["field"]?.value;
      errors = {
        ...errors,
        [field]: errorMsg,
      };
      err = true;

      if (!focusEl) focusEl = el;
    }

    if ((el.type == "text" || el.type == "date") && el.required && !el.value) {
      let field = el.attributes['name']?.value;

      errors = {
        ...errors,
        [field]: errorMsg,
      };
      err = true;
      if (!focusEl) focusEl = el;
    }
  });

  this.setState({ errors });

  if (focusEl) focusEl.focus();

  return err;
}

/**Below functions are for Functional Components */
export function validateForm(form, errors) {
  let errorMsg = "This field is required.";
  let focusEl = null;

  Object.keys(form).forEach((index) => {
    let el = form[index];
    if (el.type == "tel" && el.value) {
      let field = el.attributes["field"].value;
      let num = el.value.replace(/[^0-9]/g, "");
      if (num.length < 10) {
        errors = {
          ...errors,
          [field]: "Please provide a 10 Digit phone",
        };
        if (!focusEl) focusEl = el;
      }
    }
    if (el.type == "checkbox" && el.required && !el.checked) {
      let field = el.attributes["field"].value;
      errors = {
        ...errors,
        [field]: "Please agree to continue",
      };

      if (!focusEl) focusEl = el;
    } else if (el.required && !el.value) {
      let field = el.attributes["field"].value;
      errors = {
        ...errors,
        [field]: errorMsg,
      };

      if (!focusEl) focusEl = el;
    }
  });

  if (focusEl) focusEl.focus();

  return errors;
}

export function navigateTo(to, rehire, props) {
  //common navigation function for all docs in list
  let { doc, hash } = props.match.params;
  let userDocs;
  if (props.giss) {
    if (rehire) {
      userDocs = props.docs.userDocs.filter((doc) => doc.docs_categories_id === 5); // Rehire
    } else {
      userDocs = props.docs.userDocs.filter((doc) => doc.docs_categories_id === 4); // Onboarding
    }
  } else {
    if (rehire) {
      userDocs = props.docs.userDocs.filter((doc) => doc.docs_categories_id === 2); // Rehire
    } else {
      userDocs = props.docs.userDocs.filter((doc) => doc.docs_categories_id === 1); // Onboarding
    }
  }

  let currentDocIndex = userDocs.findIndex((userDoc) => userDoc.slug == doc);

  let path = props.match.path;

  if (hash) {
    path = path.replace(":hash", hash);
  }

  let nextDoc = {};
  let prevDoc = {};

  if (currentDocIndex < userDocs.length - 1) nextDoc = userDocs[currentDocIndex + 1];

  if (currentDocIndex > 0) prevDoc = userDocs[currentDocIndex - 1];

  if (props.adminMode) {
    if (props.resetForm) {
      props.resetForm();
    }
  }

  switch (to) {
    case "next":
      if (nextDoc && nextDoc.id) {
        path = path.replace(":doc", nextDoc.slug);
        props.history.push(`${path}?rehire=${rehire}`);
      }
      break;
    case "prev":
      if (prevDoc && prevDoc.id) {
        path = path.replace(":doc", prevDoc.slug);
        props.history.push(`${path}?rehire=${rehire}`);
      }
      break;
    default:
      props.history.push(to);
      break;
  }
}