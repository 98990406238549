import React from "react";

const ApplicantStatusCard = (props) => {
  const { title, content } = props;
  return (
    <div className="ApplicantStatusCard">
      <p className="applicant-status-title">{title}</p>
      <div className="applicant-status-content">{content}</div>
    </div>
  );
};

export default ApplicantStatusCard;
