import { docsActions } from "_actions";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import moment from "moment";
import TimeOffRequest from "Docs/Forms/TimeOffRequest";
import { config } from "_helpers/config";
import "../assets/styles/docs.scss";
import DocStatusTag from "_components/utils/DocStatusTag";
import { dateTimeConstants } from "_constants";

const DESC_CLASS = "sort-arrow desc";
const ASC_CLASS = "sort-arrow asc";

const initialStyleState = {
  documentClass: ASC_CLASS,
  employeeClass: ASC_CLASS,
  createdClass: ASC_CLASS,
  updatedClass: ASC_CLASS,
};

const Sort = {
  Asc: "asc",
  Dsc: "desc",
};

const TORRequests = (props) => {
  const { type, setSortParams } = props;

  const dispatch = useDispatch();

  const [{ employeeClass, createdClass, updatedClass }, setStyleState] =
    useState(initialStyleState);
  const [order, setOrder] = useState(false); /** true === asc **/
  const [sortItem, setSortItem] = useState("updated_at");
  const [page, setPage] = useState(0);
  const [data, setData] = useState("");
  const [filteredData, setFilteredData] = useState("");
  const [viewListing, setViewListing] = useState(true);
  const [viewDoc, setViewDoc] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState("");

  const fetchPendingDocuments = async (newPage, sort, order, type) => {
    if (type) {
      let filter = {
        page: newPage || 1,
        sort: sort || "updated_at",
        order: order || "desc",
      };
      const res = await dispatch(docsActions.getTORDocs(filter, type));
      if (res?.success) {
        setData(res?.data);
        setFilteredData(res?.data?.data);
      }
    }
  };

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
    setSortParams(sort, nextOrder);
  };

  const viewDocument = (item) => {
    setViewListing(false);
    setViewDoc(true);
    setSelectedDoc(item);
  };

  const goBack = () => {
    setViewListing(true);
    setViewDoc(false);
    setSelectedDoc("");
  };

  const handlePageChange = (newPage) => {
    const sortOrder = order ? Sort.Asc : Sort.Dsc;
    fetchPendingDocuments(newPage?.selected + 1, sortItem, sortOrder, type);
    setPage(newPage?.selected + 1);
  };

  const goToTORRequests = () => {
    window.location.reload();
  };

  useEffect(() => {
    const sortOrder = order ? Sort.Asc : Sort.Dsc;
    if (sortItem) {
      fetchPendingDocuments(page, sortItem, sortOrder, type);
    }
  }, [sortItem, order]);

  return (
    <>
      {viewListing && (
        <>
          <div className="history_lists outer_list table__scroll">
            <table className="mobile_hide report_list_width_full">
              <thead>
                <tr className="report_list_table_row">
                  <th
                    onClick={() =>
                      sortList("employeeClass", employeeClass, "first_name")
                    }
                  >
                    <span>Employee</span>
                    <span className={employeeClass} />
                  </th>
                  <th>
                    <span>Type</span>
                  </th>
                  <th>
                    <span>Requested Dates Off</span>
                  </th>
                  <th>
                    <span>Time Off w/Pay</span>
                  </th>
                  <th
                    onClick={() =>
                      sortList("createdClass", createdClass, "created_at")
                    }
                  >
                    <span>Date Submitted</span>
                    <span className={createdClass} />
                  </th>
                  <th>
                    <span>Date Approved/Denied by Payroll</span>
                  </th>
                  <th>
                    <span>Date Approved/Denied by Sup</span>
                  </th>
                  <th>
                    <span>Date Approved/Denied by ESC</span>
                  </th>
                  <th>
                    <span></span>
                  </th>
                </tr>
              </thead>
              <tbody className="report_list_table_tbody">
                {!filteredData ? (
                  <tr>
                    <td colSpan="4">
                      <div className="dashboard_expiration_date_no_data">
                        <img
                          className="loader"
                          alt="Loader"
                          src={require("../assets/images/loader.gif").default}
                        />
                      </div>
                    </td>
                  </tr>
                ) : (
                  filteredData?.map((item, index) => {
                    const name = item?.first_name + " " + item?.last_name;
                    let requestedDayOff = "";
                    let type = "";
                    if (item?.request_for_time_off_check) {
                      requestedDayOff =
                        item?.time_off_from + " to " + item?.time_off_to;
                      type = "Request for Time Off";
                    } else if (item?.request_sick_pay_check) {
                      requestedDayOff = item?.sick_pay_dates
                        .split(",")
                        .map((day, index, array) => (
                          <span key={index}>
                            {day}{index < array.length - 1 && ","}
                            <br />
                          </span> // Use <br /> to create a new line for each date
                        ));
                      type = "Request for Sick Pay";
                    } else if (item?.pph_inquiry_check) {
                      type = "PPH Inquiry";
                    } else if (item?.cash_out_pph_check) {
                      type = "Cash out PPH";
                    }

                    return (
                      <tr key={index}>
                        {/* <td onClick={() => viewDoc(item)}>
                      {item?.docs_category_name ?? "-"}
                    </td> */}
                        <td>
                          <b>{name ?? "-"}</b>
                        </td>
                        <td>{type}</td>
                        <td>{requestedDayOff}</td>
                        <td>
                          <b>
                            {item?.request_for_time_off_check === "1" &&
                            item?.with_pay_hours &&
                            item?.approved_date
                              ? item.with_pay_hours
                              : "-"}
                          </b>
                        </td>
                        <td>
                          {moment(
                            item?.date,
                            dateTimeConstants.DATE_FORMAT
                          ).format(dateTimeConstants.DATE_FORMAT)}
                        </td>
                        <td>
                          {item?.payroll_submission_date
                            ? item?.payroll_submission_date
                            : item?.request_for_time_off_check === "1" &&
                              item?.with_pay_hours
                            ? "-"
                            : "NA"}
                        </td>

                        <td>
                          {item?.superintendent_signature_date ? (
                            moment(
                              item?.superintendent_signature_date,
                              "MM-DD-YYYY HH:mm:ss"
                            ).format("MM/DD/YYYY")
                          ) : item?.superintendent_signature_required &&
                            type == "pending" ? (
                            <DocStatusTag
                              status="Pending"
                              bgColor="#c9c188"
                              fontColor=""
                            />
                          ) : (
                            "NA"
                          )}
                        </td>
                        <td>
                          {item?.approved_date ? (
                            moment(
                              item?.approved_date,
                              "MM-DD-YYYY HH:mm:ss"
                            ).format("MM/DD/YYYY")
                          ) : type === "pending" ? (
                            <DocStatusTag
                              status="Pending"
                              bgColor="#c9c188"
                              fontColor=""
                            />
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          <span>
                            <button
                              className="button plain"
                              onClick={() => viewDocument(item)}
                            >
                              <img
                                src={`${config.assetUrl}icons/doc-admin-view.svg`}
                                alt=""
                              />
                            </button>
                          </span>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>

            {/* Mobile */}
            <TORRequestsMobileView
              data={data}
              fetchPendingDocuments={fetchPendingDocuments}
              setSortParams={setSortParams}
              page={page}
            />
          </div>
          <div className="fs-pagination-wrapper-outer" key={7}>
            <div className="customised-pagination right-align">
              <ReactPaginate
                forcePage={page ? page - 1 : page}
                previousLabel={""}
                nextLabel={""}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={data?.last_page}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={(data) => handlePageChange(data)}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </>
      )}
      {viewDoc && (
        <section className="content onboarding-docs">
          <div className="widget_tor_container">
            <button
              id="back-button"
              className="button_back_tor"
              onClick={goToTORRequests}
            >
              Back
            </button>
            <div className="container doc_info full-width_form">
              <React.Fragment>
                <TimeOffRequest
                  goBack={() => viewDocument()}
                  isSingleDoc={true}
                  adminMode={true}
                  docDetails={selectedDoc}
                />
              </React.Fragment>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default TORRequests;

const TORRequestsMobileView = ({
  setSortParams,
  type,
  data,
  fetchPendingDocuments,
}) => {
  const [{ employeeClass, createdClass, updatedClass }, setStyleState] =
    useState(initialStyleState);

  const [order, setOrder] = useState(true); /** true === asc **/
  const [sortItem, setSortItem] = useState("updated_at");
  const [filteredData, setFilteredData] = useState("");
  const [viewListing, setViewListing] = useState(true);
  const [viewDoc, setViewDoc] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState("");

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
    setSortParams?.(sort, nextOrder);
  };
  const viewDocument = (item) => {
    setViewListing(false);
    setViewDoc(true);
    setSelectedDoc(item);
  };

  const goBack = () => {
    setViewListing(true);
    setViewDoc(false);
    setSelectedDoc("");
  };

  useEffect(() => {
    const sortOrder = order ? "asc" : "desc";
    if (sortItem) {
      fetchPendingDocuments(0, sortItem, sortOrder, type);
    }
  }, [sortItem, order]);

  useEffect(() => {
    setFilteredData(data?.data);
  }, [data]);

  const goToTORRequests = () => {
    window.location.reload();
  };

  return (
    <>
      {viewListing &&
        (filteredData ? (
          filteredData?.map((item, index) => {
            const name = item?.first_name + " " + item?.last_name;
            let requestedDayOff = "";
            let type = "";
            if (item?.request_for_time_off_check) {
              requestedDayOff =
                item?.time_off_from + " to " + item?.time_off_to;
              type = "Request for Time Off";
            } else if (item?.request_sick_pay_check) {
              requestedDayOff = item?.sick_pay_dates
                .split(",")
                .map((day, index, array) => (
                  <span key={index}>
                    {day}{index < array.length - 1 && ","}
                    <br />
                  </span> // Use <br /> to create a new line for each date
                ));
              type = "Request for Sick Pay";
            } else if (item?.pph_inquiry_check) {
              type = "PPH Inquiry";
            } else if (item?.cash_out_pph_check) {
              type = "Cash out PPH";
            }

            return (
              <React.Fragment key={index}>
                <div className="for_mobile_history_list">
                  <hr></hr>
                  <div className="history_items">
                    <div className="status_item">
                      <div
                        className="list_item"
                        onClick={() =>
                          sortList("employeeClass", employeeClass, "first_name")
                        }
                      >
                        <strong>Employee</strong>
                        <span className={employeeClass} />
                      </div>
                      <div className="list_item">{name}</div>
                    </div>

                    <div className="status_item">
                      <div className="list_item">
                        <strong>Type</strong>
                      </div>
                      <div className="list_item">{type}</div>
                    </div>

                    <div className="status_item">
                      <div className="list_item">
                        <strong>Requested Dates Off</strong>
                      </div>
                      <div className="list_item">{requestedDayOff}</div>
                    </div>

                    <div className="status_item">
                      <div className="list_item">
                        <strong>Time Off w/Pay</strong>
                      </div>
                      <div className="list_item">
                        {item.request_for_time_off_check === "1" &&
                        item.with_pay_hours &&
                        item.approved_date
                          ? item.with_pay_hours
                          : "-"}
                      </div>
                    </div>

                    <div className="status_item">
                      <div
                        className="list_item"
                        onClick={() =>
                          sortList("createdClass", createdClass, "created_at")
                        }
                      >
                        <strong>Date Submitted</strong>
                        <span className={createdClass} />
                      </div>
                      <div className="list_item">
                        {moment(
                          item?.date,
                          dateTimeConstants.DATE_FORMAT
                        ).format(dateTimeConstants.DATE_FORMAT)}
                      </div>
                    </div>

                    <div className="status_item">
                      <div className="list_item">
                        <strong>Date Approved/Denied by Payroll</strong>
                      </div>
                      <div className="list_item">
                        {item?.payroll_submission_date
                          ? item?.payroll_submission_date
                          : ((item?.request_for_time_off_check === "1" &&
                            item?.with_pay_hours) || (item?.request_sick_pay_check == '1' && item?.use_pph))
                          ? "-"
                          : "NA"}
                      </div>
                    </div>

                    <div className="status_item">
                      <div className="list_item">
                        <strong>Date Approved/Denied by Sup</strong>
                      </div>
                      <div className="list_item">
                        {item?.superintendent_signature_date ? (
                          moment(
                            item?.superintendent_signature_date,
                            "MM-DD-YYYY HH:mm:ss"
                          ).format("MM/DD/YYYY")
                        ) : item?.superintendent_signature_required ? (
                          <DocStatusTag
                            status="Pending"
                            bgColor="#c9c188"
                            fontColor=""
                          />
                        ) : (
                          "NA"
                        )}
                      </div>
                    </div>

                    <div className="status_item">
                      <div className="list_item">
                        <strong>Date Approved/Denied by ESC</strong>
                      </div>
                      <div className="list_item">
                        {item?.approved_date ? (
                          moment(
                            item?.approved_date,
                            "MM-DD-YYYY HH:mm:ss"
                          ).format("MM/DD/YYYY")
                        ) : type === "pending" ? (
                          <DocStatusTag
                            status="Pending"
                            bgColor="#c9c188"
                            fontColor=""
                          />
                        ) : (
                          "-"
                        )}
                      </div>
                    </div>

                    <div className="status_item">
                      <div className="list_item">
                        <span>
                          <button
                            className="button plain"
                            onClick={() => viewDocument(item)}
                          >
                            <img
                              src={`${config.assetUrl}icons/doc-admin-view.svg`}
                              alt=""
                            />
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          })
        ) : (
          <div className="for_mobile_history_list">
            <div className="dashboard_expiration_date_no_data">
              <img
                className="loader"
                alt="Loader"
                src={require("../assets/images/loader.gif").default}
              />
            </div>
          </div>
        ))}
      {viewDoc && (
        <section className="content onboarding-docs">
          <div className="widget_tor_container">
            <button
              id="back-button"
              className="button_back_tor"
              onClick={goToTORRequests}
            >
              Back
            </button>
            <div className="container doc_info full-width_form">
              <React.Fragment>
                <TimeOffRequest
                  goBack={() => viewDocument()}
                  isSingleDoc={true}
                  adminMode={true}
                  docDetails={selectedDoc}
                />
              </React.Fragment>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
