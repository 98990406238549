/* eslint-disable eqeqeq */
import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { config } from "_helpers/config";
import _ from "lodash";
import DatePicker from "react-datepicker";
import { docsActions } from "_actions/docs.actions";
import { usPhone } from "_utils/formatter";
import { toast } from "react-toastify";
import moment from "moment";
import { SignPad } from "Docs/Components";
import { FormNav } from "./FormNav";
import { navigate, validate } from "./formHandlers";
import checkIfDocsShouldBeLocked from "_helpers/lockDocs";

class WageRateAgreement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rehire_doc: false,
      isNotesOpen: false,
      pages: [1, 2],
      page: 1,
      user: {
        mailing_address: {
          country: "",
        },
      },
      form: {
        signed_date: moment().format("MM/DD/YYYY"),
        employer_signed_date: moment().format("MM/DD/YYYY"),
      },
      errors: {},
      form_edited: false,
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.validate = validate.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleSignature = this.handleSignature.bind(this);
    this.navigate = navigate.bind(this);
    this.navigatePage = this.navigatePage.bind(this);
    this.toggleList = this.toggleList.bind(this);
    this.skipHandler = this.skipHandler.bind(this);
    this.goBack = this.goBack.bind(this);
    // Refs
    this.form = React.createRef();
    this.signContainer = React.createRef();
  }

  toggleList(list) {
    switch (list) {
      case "notes":
        this.state.isNotesOpen
          ? this.setState({ isNotesOpen: false })
          : this.setState({ isNotesOpen: true });
        break;
      case "changes":
        this.state.isChangesOpen
          ? this.setState({ isChangesOpen: false })
          : this.setState({ isChangesOpen: true });
        break;
      case "lost":
        this.state.isLostOpen
          ? this.setState({ isLostOpen: false })
          : this.setState({ isLostOpen: true });
        break;
      case "discrepancies":
        this.state.isDiscrepanciesOpen
          ? this.setState({ isDiscrepanciesOpen: false })
          : this.setState({ isDiscrepanciesOpen: true });
        break;
      default:
        this.setState({
          isListOpen: false,
        });
        break;
    }
  }

  handleSignature(field, employee_signature) {
    let nextState = this.state;
    _.set(nextState, field, employee_signature);
    this.setState(
      {
        ...nextState,
        errors: {
          ...this.state.errors,
          [field]: "",
        },
      },
      () => {
        // might need to call some handlers later
      },
    );
  }

  handleDateChange(field, date) {
    let nextState = this.state;
    if (date){
      _.set(nextState, field, date.format("MM/DD/YYYY"));
    }
    this.setState({
      ...nextState,
      errors: {
        [field]: "",
      },
    });
  }

  navigatePage(to) {
    let { page } = this.state;

    switch (to) {
      case "next":
        this.setState({ page: page + 1 });
        return;
      case "prev":
        this.setState({ page: page - 1 });
        return;
      default:
        return;
    }
  }

  handleFormChange(e) {
    // safety check for datepicker
    if (e.target && e.target.attributes["field"]) {
      let field = e.target.attributes["field"].value;
      let nextState = this.state;
      let { value, checked, type } = e.target;

      if (type == "checkbox") {
        _.set(nextState, field, checked);
      } else {
        _.set(nextState, field, value);
      }

      this.setState({
        ...nextState,
        errors: {
          ...this.state.errors,
          [field]: "",
        },
      });
    }
    !this.state.form_edited && this.setState({ form_edited: true });
  }

  handlePhoneChange(e) {
    e.target.value = usPhone(e.target.value);
  }

  skipHandler() {
    let { doc } = this.state;

    let data = {
      id: doc.id,
      doc_type: doc.docs_type_id,
      skippedPages: [1],
    };

    if (this.props.adminMode) {
      data.admin_mode = this.props.adminMode;
    }

    this.state.rehire_doc && (data.rehire = true);

    this.props.dispatch(docsActions.saveDoc(data)).then((res) => {
      if (res.success) {
        if (this.props.mobileMode) {
          this.props.setScreen("sidebar");
        } else {
          if (this.props.lastPage) {
            if (this.props.adminMode) {
              this.props.toggle()
            } else {
              this.props.single_doc
                ? this.props.tabChange("list") // single_doc
                : this.props.history.push(`/dashboard`);
            }
          } else {
            this.navigate("next", this.props.rehire);
          }
        }
      } else {
        toast.error(res.message);
      }
    });
  }

  submitForm(e) {
    e.preventDefault();

    let err = this.validate();

    if (err) {
      return;
    }

    let { form, doc, user, page, pages } = this.state;
    let { adminMode } = this.props;

    if (adminMode && pages && page < pages.length) {
      this.navigatePage("next");
      return;
    }

    let data = {
      id: doc.id,
      doc_type: doc.docs_type_id,
      form,
      user,
      page,
    };

    if (this.props.adminMode) {
      data.admin_mode = this.props.adminMode;
    }
    this.props.single_doc ? (data.single_doc = true) : (data.single_doc = false);
    this.props.rehire ? (data.rehire = true) : (data.rehire = false);

    this.props.dispatch(docsActions.saveDoc(data)).then((res) => {
      if (res.success) {
        if (this.props.mobileMode && !this.props.lastPage) {
          this.props.setScreen("sidebar");
          this.navigate("next", this.props.rehire);
        } else if (this.props.lastPage) {
          if (this.props.adminMode) {
            this.props.history.push(`/docs`);
          } else {
            this.props.history.push(`/dashboard`);
          }
        } else {
          this.navigate("next", this.props.rehire);
        }
      } else {
        toast.error(res.message);
      }
    });
  }

  // GET FORM data from api with id passed in props
  getFormData(id) {
    this.props.dispatch(docsActions.getForm(id)).then((res) => {
      if (res.success) {
        const currentState = this.state;
        let { user, form } = res.data;
        // date needs to a moment object or Datepicker breaks.
        form.signed_date = form.signed_date || currentState.form.signed_date;
        form.dob = form.dob || currentState.form.dob;
        form.info_given_date = form.info_given_date || currentState.form.info_given_date;
        form.hired_date = form.hired_date || currentState.form.hired_date;
        form.job_offered_date = form.job_offered_date || currentState.form.job_offered_date;
        form.job_started_date = form.job_started_date || currentState.form.job_started_date;
        form.employer_signed_date =
          form.employer_signed_date || currentState.form.employer_signed_date;
        form.position=user.position || currentState.form.position;
        if (!form?.employee_printed_name) {
          form.employee_printed_name = user?.full_name ?? ""
        }
        
        // spread the data coming from API into state
        this.setState({
          user,
          form,
        });
      }
    });
  }

  goBack() {
    // this.props.toggle();
    if (this.state.form_edited) {
      this.props.toggleCloseConfirmation(true);
    } else {
      this.props.setScreen("sidebar");
    }
  }

  componentDidMount() {
    let docSlug = this.props.match.params.doc;
    let doc;
    // if (this.props.rehire) {
    //   doc = this.props.docs.userDocs.find(
    //     (doc) => doc.slug === docSlug && doc.docs_categories_id === 2,
    //   );
    // } else {
    //   doc = this.props.docs.userDocs.find((doc) => doc.slug === docSlug && doc.docs_categories_id == 1);
    // }
    if (this.props.giss) {
      if (this.props.rehire) {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id === 5,
        );
      } else {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id == 4,
        );
      }
    } else {
      if (this.props.rehire) {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id === 2,
        );
      } else {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id == 1,
        );
      }
    }
    const height = this.signContainer.clientHeight;
    const width = this.signContainer.clientWidth;

    this.setState({
      doc,
      sign: {
        height: height,
        width: width,
      },
    });

    this.getFormData(doc.id);
  }

  render() {
    let is_admin,
      { user, form, errors, page, pages } = this.state;
    let { adminMode } = this.props;
    if (this.props.user && this.props.user.profile) {
      is_admin = this.props.user.profile.data.is_admin;
    }


    const shouldLockDocs = checkIfDocsShouldBeLocked(user, form, adminMode)
    return (
      <div className='doc_info-rh'>
        {/* Title */}
        <div className='doc-title'>
          <img src={`${config.assetUrl}icons/doc-title-img.svg`} className='doc-title-img' />
          <span className='close-button' onClick={this.goBack}>
            <img src={`${config.assetUrl}icons/back-btn.svg`} />
          </span>
          <h3>Wage Rate Agreement &nbsp;&nbsp;<span className='opt_text'>OPTIONAL</span></h3>
        </div>

        {/* static HTML markup */}
        <div className='doc_info-form wage-rate-agreement'>
          <form
            className='info-form'
            ref={this.form}
            onChange={this.handleFormChange}
            onSubmit={this.submitForm}
            name='wage_rate_agreement'
            noValidate>
           <fieldset disabled={shouldLockDocs}>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Employee Name<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <input
                      type='text'
                      className={`${errors["form.employee_printed_name"] ? "error" : ""}`}
                      name='employee_printed_name'
                      id='employee_printed_name'
                      value={form.employee_printed_name}
                      field='form.employee_printed_name'
                      placeholder='- Enter here -'
                    />
                    {errors["form.employee_printed_name"] && (
                      <label htmlFor='employee_printed_name' className='error_label'>
                        {errors["form.employee_printed_name"]}
                      </label>
                    )}
                  </div>
                </div>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Position<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <select
                      className={`${errors["form.position"] ? "error" : ""}`}
                      name='position'
                      id='position'
                      defaultValue={form.position}
                      field='form.position'
                      required>
                      <option value=''>- Select -</option>
                      <option value='Crew' selected={form.position === 'Crew'}>Crew</option>
                      <option value='Engine' selected={form.position === 'Engine'}>Engine</option>
                      <option value='Fuels' selected={form.position === 'Fuels'}>Fuels</option>
                      <option value='Admin' selected={form.position === 'Admin'}>Admin</option>
                      <option value='Other' selected={form.position === 'Other'}>Other</option>
                    </select>
                    {errors["form.position"] && (
                      <label htmlFor='position' className='error_label'>
                        {errors["form.position"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head mb-0'>Payday</div>
                  <p className='mb-0 opacity-1'>Bi‐weekly on Fridays. Pay Period runs Sunday‐Saturday.</p>
                </div>
              </div>
            </div>

            <div className='row form-row pb-2'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>Pay Rates</div>

                  <table className='content_table pay-rates'>
                    <tbody>
                      <tr>
                        <td width='26%'>GEH Rate for Fire* (does not include H&W)</td>
                        <td>: $26.48 </td>
                      </tr>
                      <tr>
                        <td>HWD Rate for private jobs</td>
                        <td>: $16.20 </td>
                      </tr>
                      <tr>
                        <td>HWD-UI Rate for UI jobs</td>
                        <td>: $16.20 </td>
                      </tr>
                      <tr>
                        <td>Other </td>
                        <td>: $16.20 (i.e. Admin/shop)</td>
                      </tr>
                      <tr>
                        <td>GSH</td>
                        <td>: Varies by Wage Determination per contract on Gov Projects.</td>
                      </tr>
                      <tr>
                        <td>PPT RATE</td>
                        <td>: Pre, Post & Travel paid at minimum wage unless otherwise stated.</td>
                      </tr>
                    </tbody>
                  </table>

                  <span className='notes'>
                    * Wage checklist must be attached if more than minimum wage.
                  </span>
                </div>
              </div>
            </div>

            <div className='row form-row'>
              <div className='col'>
                <div className='disclaimers dis-notes'>
                  {/* <!--add className toggled--> */}
                  {/* <button className="toggler_btn"></button> */}
                  <div className='inputs-head'>
                    NOTES
                    <button
                      type='button'
                      className={`toggler_btn ${this.state.isNotesOpen ? "toggled" : ""}`}
                      onClick={() => this.toggleList("notes")}></button>
                  </div>
                  {this.state.isNotesOpen && (
                    <Fragment>
                      <ul>
                        <li>
                          Above rates are paid <strong>HOURLY</strong>. Overtime & Double‐time paid
                          in accordance to DOL regulations.
                        </li>
                        <li>
                          Wages will be adjusted to comply with current California minimum wage
                          laws.
                        </li>
                        <li>Pre/Post/Travel may be at minimum wage.</li>
                        <li>Other pay rates for specific projects may be agreed upon inwriting.</li>
                        <li>
                          Refer to current Employee Handbook for detailed explanation on Pay Rates,
                          Pay Codes and other information.
                        </li>
                      </ul>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>

            <div className='row form-row employee-signature'>
              <div className='col'>
                <span className='notes'>
                  By signing this form, Employee understands and agrees to the terms of pay set
                  forth above. Any change to this agreement must be made in writing and signed by
                  Employee and Employer Representative.
                </span>
              </div>

              {/* Signature */}
              <div className='col'>
                <div className='inputs-col w-50 sign-input'>
                  <div className='inputs-head'>
                    Employee Signature<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <div
                      ref={(element) => {
                        this.signContainer = element;
                      }}
                      className='sign_here'>
                      {this.state.sign && (
                        <Fragment>
                          <SignPad
                            id="wageEmpSignPad"
                            height={this.state.sign.height}
                            width={this.state.sign.width}
                            handleSignature={this.handleSignature.bind(
                              this,
                              "form.employee_signature",
                            )}
                            signature={form.employee_signature}
                            disabled={adminMode}
                          />
                          <input
                            type='hidden'
                            value={form.employee_signature}
                            name='employee_signature'
                            field='form.employee_signature'
                            required></input>
                          {!adminMode && errors["form.employee_signature"] && (
                            <label htmlFor='employee_signature' className='error_label'>
                              {errors["form.employee_signature"]}
                            </label>
                          )}
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>
                <div className='inputs-col w-50'>
                  <div className='inputs-head'>
                    Date<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <DatePicker
                      className={`${errors["form.signed_date"] ? "error" : ""} w-100`}
                      name='signed_date'
                      id='signed_date'
                      required
                      selected={moment(form.signed_date)}
                      field='form.signed_date'
                      placeholder='mm/dd/yyyy'
                      onChange={this.handleDateChange.bind(this, "form.signed_date")}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode='select'
                      autoComplete='off'
                      disabled={true}
                    />
                    {errors["form.signed_date"] && (
                      <label htmlFor='signed_date' className='error_label'>
                        {errors["form.signed_date"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
              {adminMode && (
                <section>
                  {/* Title and Name */}
                  <div className='col'>
                    <div className='inputs-head'>Employer Representative</div>
                  </div>
                  <div className='col'>
                    <div className='inputs-col w-50'>
                      <div className='inputs-head'>
                        Printed Name<span className='required-star'>&#x2A;</span>
                      </div>
                      <div className='inputs-items'>
                        <input
                          type='text'
                          className={`${errors["form.employer_printed_name"] ? "error" : ""}`}
                          name='employer_printed_name'
                          id='employer_printed_name'
                          value={form.employer_printed_name}
                          field='form.employer_printed_name'
                          placeholder='- Enter here -'
                          disabled={!is_admin}
                          required
                        />
                        {errors["form.employer_printed_name"] && (
                          <label htmlFor='employer_printed_name' className='error_label'>
                            {errors["form.employer_printed_name"]}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className='inputs-col w-50'>
                      <div className='inputs-head'>
                        Title<span className='required-star'>&#x2A;</span>
                      </div>
                      <div className='inputs-items'>
                        <input
                          type='text'
                          className={`${errors["form.employer_title"] ? "error" : ""}`}
                          name='employer_title'
                          id='employer_title'
                          value={form.employer_title}
                          field='form.employer_title'
                          placeholder='- Enter here -'
                          disabled={!is_admin}
                          required
                        />
                        {errors["form.employer_title"] && (
                          <label htmlFor='employer_title' className='error_label'>
                            {errors["form.employer_title"]}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Admin Signature */}

                  <div className='col'>
                    <div className='inputs-col w-50 sign-input'>
                      <Fragment>
                        <div className='inputs-head'>
                          Supervisor Signature<span className='required-star'>&#x2A;</span>
                        </div>
                        <div className='inputs-items'>
                          <div
                            ref={(element) => {
                              this.signContainer = element;
                            }}
                            className='sign_here'>
                            {this.state.sign && (
                              <Fragment>
                                <input
                                  type='hidden'
                                  value={form.employer_signature}
                                  name='employer_signature'
                                  field='form.employer_signature'
                                  required
                                />
                                {errors["form.employer_signature"] && (
                                  <label htmlFor='employer_signature' className='error_label'>
                                    {errors["form.employer_signature"]}
                                  </label>
                                )}
                                <SignPad
                                  id="wageSupSignPad"
                                  height={this.state.sign.height}
                                  width={this.state.sign.width}
                                  handleSignature={this.handleSignature.bind(
                                    this,
                                    "form.employer_signature",
                                  )}
                                  signature={form.employer_signature}
                                />
                              </Fragment>
                            )}
                          </div>
                        </div>
                      </Fragment>
                    </div>
                    <div className='inputs-col w-50'>
                      <div className='inputs-head'>
                        Date<span className='required-star'>&#x2A;</span>
                      </div>
                      <div className='inputs-items'>
                        <DatePicker
                          className={`${errors["form.employer_signed_date"] ? "error" : ""} w-100`}
                          name='employer_signed_date'
                          id='employer_signed_date'
                          required
                          selected={moment(form.employer_signed_date)}
                          field='form.employer_signed_date'
                          placeholder='mm/dd/yyyy'
                          onChange={this.handleDateChange.bind(this, "form.employer_signed_date")}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode='select'
                          autoComplete='off'
                          disabled={!is_admin}
                        />
                        {errors["form.employer_signed_date"] && (
                          <label htmlFor='employer_signed_date' className='error_label'>
                            {errors["form.employer_signed_date"]}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </div>

            <div className='row form-row'>
              <div className='col'>
                <div className='disclaimers'>
                  <strong className='diss'>Disclaimer: </strong>You must read and carefully
                  understand the documents that you are filling out and signing. These are legally
                  binding documents and must be accurate. By filling these forms out, you are
                  agreeing to our{" "}
                  <a href='https://firestormfire.com/terms-of-use/' target="blank">Terms of Use, Privacy Policy and Employee Agreements.</a>
                </div>
              </div>
            </div>

            <div className='row form-row last'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='check_radio_group check_disclaimer'>
                    <div className='check_radio_item'>
                      <input
                        type='checkbox'
                        name='disclaimer_check'
                        id='disclaimer_check'
                        field='form.disclaimer_check'
                        checked={form.disclaimer_check}
                        required
                      />
                      <span className='checkbox_checkbox'></span>
                      <label htmlFor='disclaimer_check'>
                        I have confirmed the above details are true and valid.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FormNav
             single_doc={this.props.single_doc}
              adminMode={adminMode}
              navigate={this.navigate.bind(this)}
              disclaimer_check={form.disclaimer_check}
              mobileMode={this.props.mobileMode}
              skip={true}
              skipHandler={this.skipHandler.bind(this)}
              lastPage={false}
            />
            </fieldset>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { docs, user } = state;
  return { docs, user };
}

let connectedWageRateAgreement = compose(withRouter, connect(mapStateToProps))(WageRateAgreement);
export { connectedWageRateAgreement as WageRateAgreement };
