import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { config } from "_helpers/config";
import _ from "lodash";
import DatePicker from "react-datepicker";
import { docsActions } from "_actions/docs.actions";
import { toast } from "react-toastify";
import moment from "moment";
import { SignPad } from "Docs/Components";
import { FormNav } from "./FormNav";
import { navigate, validate } from "./formHandlers";
import { getCurrentUserInfo } from "_helpers/reduxSelector";
import checkIfDocsShouldBeLocked from "_helpers/lockDocs";

class EmployeeAgreement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rehire_doc: false,
      doc: {},
      form: {
        terms_check: false,
        prerequisite_check: false,
        job_desc_check: false,
        eligibility_verification_check: false,
        drug_free_check: false,
        fitness_check: false,
        inform_illenss_check: false,
        safety_rules_check: false,
        report_to_work_check: false,
        ppe_rules_check: false,
        wage_policy_check: false,
        meal_break_rules_check: false,
        confidentiality_check: false,
        handbook_policies_check: false,
        agreement_policy_check: false,
        signed_date: moment().format("MM/DD/YYYY"),
        supervisor_signed_date: moment().format("MM/DD/YYYY"),
        disclaimer_check: false,
        employee_signature: null,
      },
      errors: {},
      form_edited: false,
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleSignature = this.handleSignature.bind(this);
    this.navigate = navigate.bind(this);
    this.validate = validate.bind(this);
    this.goBack = this.goBack.bind(this);
    // Refs
    this.form = React.createRef();
    this.signContainer = React.createRef();
  }

  handleFormChange(e) {
    if (e.target && e.target.attributes["field"]) {
      let field = e.target.attributes["field"].value;
      let nextState = this.state;
      let { value, checked, type } = e.target;
      if (type === "checkbox") {
        _.set(nextState, field, checked);
      } else {
        _.set(nextState, field, value);
      }
      this.setState({
        ...nextState,
        errors: {
          ...this.state.errors,
          [field]: "",
        },
      });
    }
    !this.state.form_edited && this.setState({ form_edited: true });
  }

  handleSignature(field, employee_signature) {
    let nextState = this.state;
    _.set(nextState, field, employee_signature);
    this.setState({
      ...nextState,
      errors: {
        ...this.state.errors,
        [field]: "",
      },
    });
  }

  handleDateChange(field, date) {
    let nextState = this.state;
    if (date){
    _.set(nextState, field, date.format("MM/DD/YYYY"));
    }
    this.setState({
      ...nextState,
      errors: {
        [field]: "",
      },
    });
  }

  submitForm(e) {
    e.preventDefault();

    let err = this.validate();

    if (err) {
      return;
    }

    let { form, doc } = this.state;

    let data = {
      id: doc.id,
      doc_type: doc.docs_type_id,
      form,
    };

    if (this.props.adminMode) {
      data.admin_mode = this.props.adminMode;
    }
    this.props.single_doc ? (data.single_doc = true) : (data.single_doc = false);
    this.props.rehire ? (data.rehire = true) : (data.rehire = false);

    this.props.dispatch(docsActions.saveDoc(data)).then((res) => {
      if (res.success) {
        if (this.props.mobileMode && !this.props.lastPage) {
          this.props.setScreen("sidebar");
          this.navigate("next", this.props.rehire);
        } else if (this.props.lastPage) {
          if (this.props.adminMode) {
            this.props.history.push(`/docs`);
          } else {
            this.props.history.push(`/dashboard`);
          }
        } else {
          this.navigate("next", this.props.rehire);
        }
      } else {
        if (this.props.mobileMode) this.props.toggleErrorPopup(res.message);
        else toast.error(res.message);
      }
    });
  }

  // GET FORM data from api with id passed in props
  getFormData(id) {
    this.props.dispatch(docsActions.getForm(id)).then((res) => {
      if (res.success) {
        const currentState = this.state;
        let { form, user } = res.data;
        // date needs to a moment object or Datepicker breaks.
        form.supervisor_signed_date =
          form.supervisor_signed_date || currentState.form.supervisor_signed_date;
        form.signed_date = form.signed_date || currentState.form.signed_date;

        if (!form?.full_name) {
          form.full_name = user?.full_name ?? ""
        }
        // spread the data coming from API into state
        this.setState({
          form,
        });
      }
    });
  }

  goBack() {
    this.props.resetForm();
    if (this.state.form_edited) {
      this.props.toggleCloseConfirmation(true)
    } else {
      this.props.setScreen('sidebar');
    }
  }

  componentDidMount() {
    let docSlug = this.props.match.params.doc;
    let doc;
    // if (this.props.rehire) {
    //   doc = this.props.docs.userDocs.find(
    //     (doc) => doc.slug === docSlug && doc.docs_categories_id === 2,
    //   );
    // } else {
    //   doc = this.props.docs.userDocs.find((doc) => doc.slug === docSlug && doc.docs_categories_id == 1);
    // }
    if (this.props.giss) {
      if (this.props.rehire) {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id === 5,
        );
      } else {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id == 4,
        );
      }
    } else {
      if (this.props.rehire) {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id === 2,
        );
      } else {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id == 1,
        );
      }
    }
    const height = this.signContainer.clientHeight;
    const width = this.signContainer.clientWidth;
    this.setState({
      doc,
      sign: {
        height: height,
        width: width,
      },
    });
    this.getFormData(doc.id);
  }

  render() {
    let { form, errors, } = this.state;
    

    let { adminMode, currentUserInfo } = this.props;
    
    const shouldLockDocs = checkIfDocsShouldBeLocked(currentUserInfo, form, adminMode)

    return (
      <div className='doc_info-rh'>
        <div className='doc-title'>
          <img src={`${config.assetUrl}icons/doc-title-img.svg`} className='doc-title-img' />
          <span className='close-button' onClick={this.goBack}>
            <img src={`${config.assetUrl}icons/back-btn.svg`} />
          </span>
          <h3>Employee Agreement</h3>
        </div>
        <div className='doc_info-form employee-agreement'>
          <form
            className='info-form'
            ref={this.form}
            onChange={this.handleFormChange}
            onSubmit={this.submitForm}
            noValidate>
        <fieldset disabled={shouldLockDocs}>  
            <div className='row form-row no_border pt-0'>
              <div className='col'>
                <div className='inputs-col'>
                  <h6 className='inputs-head'>
                    FIRESTORM WILDLAND FIRE SUPPRESSION INC. EMPLOYMENT AGREEMENT
                  </h6>
                  <div className='inputs-head'>
                    Full Name<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <input
                      type='text'
                      className={`${errors["form.full_name"] ? "error" : ""}`}
                      name='full_name'
                      value={form.full_name}
                      field='form.full_name'
                      id='full_name'
                      placeholder='Enter here'
                      required
                    />
                    {errors["form.full_name"] && (
                      <label htmlFor='full_name' className='error_label'>
                        {errors["form.full_name"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className='col part1-checkbox'>
                <div className='inputs-col'>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='checkbox'
                        name='terms_check'
                        id='terms_check'
                        field='form.terms_check'
                        checked={form.terms_check}
                        required
                      />
                      <span className='checkbox_checkbox'></span>
                      <label htmlFor='terms_check'>
                        <span>
                          I, {form.full_name}, Undersigned hereinafter referred to as “Employee”,
                          hereby enter into this agreement with Firestorm Wildland Fire Suppression
                          Inc. hereinafter referred to as “Employer”. As an Employee, I agree to all
                          of the following terms of entering employment.
                        </span>
                      </label>
                    </div>
                    {errors["form.terms_check"] && (
                      <label htmlFor='terms_check' className='error_label'>
                        {errors["form.terms_check"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <span className='notes'>
                    Check each statement below as you agree and understand.
                  </span>
                </div>
              </div>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='checkbox'
                        name='prerequisite_check'
                        id='prerequisite_check'
                        field='form.prerequisite_check'
                        checked={form.prerequisite_check}
                        required
                      />
                      <span className='checkbox_checkbox'></span>
                      <label htmlFor='prerequisite_check'>
                        <strong>I Agree and Understand,</strong> Pre-requisites for employment are
                        the successful completion of fitness tests and training requirements as
                        needed. Employee confirms that all prerequisites for employment have been
                        met.
                      </label>
                    </div>
                    {errors["form.prerequisite_check"] && (
                      <label htmlFor='prerequisite_check' className='error_label'>
                        {errors["form.prerequisite_check"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='checkbox'
                        name='job_desc_check'
                        id='job_desc_check'
                        field='form.job_desc_check'
                        checked={form.job_desc_check}
                        required
                      />
                      <span className='checkbox_checkbox'></span>
                      <label htmlFor='job_desc_check'>
                        <strong>I Agree and Understand,</strong> I have received, reviewed and fully
                        understand my job description. I attest that I am physically and mentally
                        able to perform all tasks and further understand that I am responsible for
                        the satisfactory execution of the essential functions described therein, as
                        well as all conditions as described.
                      </label>
                    </div>
                    {errors["form.job_desc_check"] && (
                      <label htmlFor='job_desc_check' className='error_label'>
                        {errors["form.job_desc_check"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='checkbox'
                        name='eligibility_verification_check'
                        id='eligibility_verification_check'
                        field='form.eligibility_verification_check'
                        checked={form.eligibility_verification_check}
                        required
                      />
                      <span className='checkbox_checkbox'></span>
                      <label htmlFor='eligibility_verification_check'>
                        <strong>I Agree and Understand,</strong> Employee acknowledges that
                        employment eligibility will be verified through the E-verification system
                        operated by Dept of Homeland Security and Social Security Administration.
                      </label>
                    </div>
                    {errors["form.eligibility_verification_check"] && (
                      <label htmlFor='eligibility_verification_check' className='error_label'>
                        {errors["form.eligibility_verification_check"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='checkbox'
                        name='drug_free_check'
                        id='drug_free_check'
                        field='form.drug_free_check'
                        checked={form.drug_free_check}
                        required
                      />
                      <span className='checkbox_checkbox'></span>
                      <label htmlFor='drug_free_check'>
                        <strong>I Agree and Understand,</strong> Employee acknowledges having
                        received a copy of 52.223-05 “Certification Regarding a Drug-Free Workplace”
                        in the Employee Handbook and agrees to the terms and conditions stated
                        therein. Employees may be subject to drug testing for reasonable suspicion
                        or probable cause. Medical and recreational use of marijuana is prohibited
                        in the workplace. (See handbook for full policy).
                      </label>
                    </div>
                    {errors["form.drug_free_check"] && (
                      <label htmlFor='drug_free_check' className='error_label'>
                        {errors["form.drug_free_check"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='checkbox'
                        name='fitness_check'
                        id='fitness_check'
                        field='form.fitness_check'
                        checked={form.fitness_check}
                        required
                      />
                      <span className='checkbox_checkbox'></span>
                      <label htmlFor='fitness_check'>
                        <strong>I Agree and Understand,</strong> Employee understands that the
                        physical fitness test must be taken annually to remain on firefighting
                        status. Employee assumes sole responsibility for injuries sustained during
                        fitness training and consequences of fitness test, whether medical, physical
                        or otherwise.
                      </label>
                    </div>
                    {errors["form.fitness_check"] && (
                      <label htmlFor='fitness_check' className='error_label'>
                        {errors["form.fitness_check"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='checkbox'
                        name='inform_illenss_check'
                        id='inform_illenss_check'
                        field='form.inform_illenss_check'
                        checked={form.inform_illenss_check}
                        required
                      />
                      <span className='checkbox_checkbox'></span>
                      <label htmlFor='inform_illenss_check'>
                        <strong>I Agree and Understand,</strong> Employee agrees to inform Employer
                        of any personal injuries or illnesses and will disclose information
                        regarding any medication being taken whether prescribed or not. Employee
                        agrees to report any and all injuries immediately. All work injuries must be
                        reported within 24 hours.
                      </label>
                    </div>
                    {errors["form.inform_illenss_check"] && (
                      <label htmlFor='inform_illenss_check' className='error_label'>
                        {errors["form.inform_illenss_check"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='checkbox'
                        name='safety_rules_check'
                        id='safety_rules_check'
                        field='form.safety_rules_check'
                        checked={form.safety_rules_check}
                        required
                      />
                      <span className='checkbox_checkbox'></span>
                      <label htmlFor='safety_rules_check'>
                        <strong>I Agree and Understand,</strong> Employee understands there are the
                        written safety rules outlined in the Employee Handbook and Injury and
                        Illness Prevention Program as well as verbal safety instructions given
                        during various training &amp; tailgate safety meetings. Employee agrees to
                        abide by all rules and policies.
                      </label>
                    </div>
                    {errors["form.safety_rules_check"] && (
                      <label htmlFor='safety_rules_check' className='error_label'>
                        {errors["form.safety_rules_check"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='checkbox'
                        name='report_to_work_check'
                        id='report_to_work_check'
                        field='form.report_to_work_check'
                        checked={form.report_to_work_check}
                        required
                      />
                      <span className='checkbox_checkbox'></span>
                      <label htmlFor='report_to_work_check'>
                        <strong>I Agree and Understand,</strong> Employee understands that this is
                        an emergency job position and agrees to remain in constant communication
                        with Employer. Failure to report to work within 2 hours when called for
                        Emergency Services or failure to return calls within 24 hours for
                        nonemergency services will be considered job abandonment and Employee will
                        be discharged as a voluntary quit. Employee must notify supervisor when not
                        available for any reason.
                      </label>
                    </div>
                    {errors["form.report_to_work_check"] && (
                      <label htmlFor='report_to_work_check' className='error_label'>
                        {errors["form.report_to_work_check"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='checkbox'
                        name='ppe_rules_check'
                        id='ppe_rules_check'
                        field='form.ppe_rules_check'
                        checked={form.ppe_rules_check}
                        required
                      />
                      <span className='checkbox_checkbox'></span>
                      <label htmlFor='ppe_rules_check'>
                        <strong>I Agree and Understand,</strong> Employee agrees that PPE provided
                        by Employer is on a loan basis and must be returned in satisfactory
                        condition at the end of season. Employee authorizes Firestorm to deduct or
                        invoice the value of any gear not returned or not returned in useable
                        condition as outlined on the Gear Issue Form. Employee agrees to provide
                        personal equipment as stated in the Employee Handbook. (See Firefighter Gear
                        Checklist). Employee agrees to utilize PPE at all times necessary to ensure
                        safety.
                      </label>
                    </div>
                    {errors["form.ppe_rules_check"] && (
                      <label htmlFor='ppe_rules_check' className='error_label'>
                        {errors["form.ppe_rules_check"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='checkbox'
                        name='wage_policy_check'
                        id='wage_policy_check'
                        field='form.wage_policy_check'
                        checked={form.wage_policy_check}
                        required
                      />
                      <span className='checkbox_checkbox'></span>
                      <label htmlFor='wage_policy_check'>
                        <strong>I Agree and Understand,</strong> Employee understands that wage
                        rates vary by project(s) but will never be lower than minimum wage. See
                        handbook for compensable and non-compensable times.
                      </label>
                    </div>
                    {errors["form.wage_policy_check"] && (
                      <label htmlFor='wage_policy_check' className='error_label'>
                        {errors["form.wage_policy_check"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='checkbox'
                        name='meal_break_rules_check'
                        id='meal_break_rules_check'
                        field='form.meal_break_rules_check'
                        checked={form.meal_break_rules_check}
                        required
                      />
                      <span className='checkbox_checkbox'></span>
                      <label htmlFor='meal_break_rules_check'>
                        <strong>I Agree and Understand,</strong> Meal breaks are provided as per DOL
                        regulations. During hazardous conditions, meal breaks may not be an option.
                        In such situations, Employee agrees to work through the meal break and
                        receive appropriate compensation.<strong> Employee may revoke this agreement in
                        writing at any time.</strong>
                      </label>
                    </div>
                    {errors["form.meal_break_rules_check"] && (
                      <label htmlFor='meal_break_rules_check' className='error_label'>
                        {errors["form.meal_break_rules_check"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='checkbox'
                        name='confidentiality_check'
                        id='confidentiality_check'
                        field='form.confidentiality_check'
                        checked={form.confidentiality_check}
                        required
                      />
                      <span className='checkbox_checkbox'></span>
                      <label htmlFor='confidentiality_check'>
                        Employee agrees to maintain strict
                        confidentiality in regard to Firestorm, its clients, subcontractors,
                        vendors, documents and records, whether verbal or written as outlined in the
                        Employee Handbook and agrees to refrain from engaging in competition for a
                        period of 3 years following termination of employment unless otherwise
                        agreed upon by both parties.
                      </label>
                    </div>
                    {errors["form.confidentiality_check"] && (
                      <label htmlFor='confidentiality_check' className='error_label'>
                        {errors["form.confidentiality_check"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='checkbox'
                        name='handbook_policies_check'
                        id='handbook_policies_check'
                        field='form.handbook_policies_check'
                        checked={form.handbook_policies_check}
                        required
                      />
                      <span className='checkbox_checkbox'></span>
                      <label htmlFor='handbook_policies_check'>
                        <strong>I Agree and Understand,</strong> Employee agrees to read the current
                        Employee Handbook and abide by all policies outlined therein. Non-compliance
                        with any policy or agreement in the handbook will result in disciplinary
                        action or termination as an Employee.
                      </label>
                    </div>
                    {errors["form.handbook_policies_check"] && (
                      <label htmlFor='handbook_policies_check' className='error_label'>
                        {errors["form.handbook_policies_check"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='checkbox'
                        name='agreement_policy_check'
                        id='agreement_policy_check'
                        field='form.agreement_policy_check'
                        checked={form.agreement_policy_check}
                        required
                      />
                      <span className='checkbox_checkbox'></span>
                      <label htmlFor='agreement_policy_check'>
                        <strong>I Agree and Understand,</strong> Any alteration or modification of
                        this Agreement shall be made in writing and agreed to by both parties prior
                        to commencement of employment. This Agreement supersedes all prior
                        agreements. Your employment with FWFS is a voluntary one and is subject to
                        termination by you or FWFS at will, with or without cause, and with or
                        without notice, at any time.
                      </label>
                    </div>
                    {errors["form.agreement_policy_check"] && (
                      <label htmlFor='agreement_policy_check' className='error_label'>
                        {errors["form.agreement_policy_check"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  In witness whereof, agree to the above and have indicated so by signing below.
                </div>
              </div>
              <div className='col'>
                <div className='inputs-col w-50 sign-input'>
                  <div className='inputs-head'>
                    Employee Signature<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <div
                      ref={(element) => {
                        this.signContainer = element;
                      }}
                      className='sign_here'>
                      {this.state.sign && (
                        <Fragment>
                          <SignPad
                            id="agreementEmpSignPad"
                            height={this.state.sign.height}
                            width={this.state.sign.width}
                            handleSignature={this.handleSignature.bind(
                              this,
                              "form.employee_signature",
                            )}
                            signature={form.employee_signature}
                            disabled={adminMode}
                          />
                          <input
                            type='hidden'
                            value={form.employee_signature}
                            name='employee_signature'
                            field='form.employee_signature'
                            required></input>
                          {errors["form.employee_signature"] && (
                            <label htmlFor='employee_signature' className='error_label'>
                              {errors["form.employee_signature"]}
                            </label>
                          )}
                        </Fragment>
                      )}
                    </div>
                    <div className='inputs-head'>
                      Date<span className='required-star'>&#x2A;</span>
                    </div>
                    <div className='inputs-items'>
                      <DatePicker
                        className={`${errors["form.signed_date"] ? "error" : ""} w-100`}
                        name='signed_date'
                        id='signed_date'
                        required
                        selected={moment(form.signed_date)}
                        field='form.signed_date'
                        placeholder='mm/dd/yyyy'
                        onChange={this.handleDateChange.bind(this, "form.signed_date")}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode='select'
                        autoComplete='off'
                        disabled={true}
                      />
                      {errors["form.signed_date"] && (
                        <label htmlFor='signed_date' className='error_label'>
                          {errors["form.signed_date"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
                <div className='inputs-col w-50 sign-input'>
                  {adminMode && (
                    <Fragment>
                      <div className='inputs-head'>
                        Supervisor Signature<span className='required-star'>&#x2A;</span>
                      </div>
                      <div className='inputs-items'>
                        <div
                          ref={(element) => {
                            this.signContainer = element;
                          }}
                          className='sign_here'>
                          {this.state.sign && (
                            <Fragment>
                              <input
                                type='hidden'
                                value={form.supervisor_signature}
                                name='supervisor_signature'
                                field='form.supervisor_signature'
                                required></input>
                              {errors["form.supervisor_signature"] && (
                                <label htmlFor='supervisor_signature' className='error_label'>
                                  {errors["form.supervisor_signature"]}
                                </label>
                              )}
                              <SignPad
                                id="agreementSupSignPad"
                                height={this.state.sign.height}
                                width={this.state.sign.width}
                                handleSignature={this.handleSignature.bind(
                                  this,
                                  "form.supervisor_signature",
                                )}
                                signature={form.supervisor_signature}
                              />
                            </Fragment>
                          )}
                        </div>
                        <div className='inputs-head'>
                          Date<span className='required-star'>&#x2A;</span>
                        </div>
                        <div className='inputs-items'>
                          <DatePicker
                            className={`${
                              errors["form.supervisor_signed_date"] ? "error" : ""
                              } w-100`}
                            name='supervisor_signed_date'
                            id='supervisor_signed_date'
                            required
                            selected={moment(form.supervisor_signed_date)}
                            field='form.supervisor_signed_date'
                            placeholder='mm/dd/yyyy'
                            onChange={this.handleDateChange.bind(
                              this,
                              "form.supervisor_signed_date",
                            )}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode='select'
                            autoComplete='off'
                            disabled={true}
                          />
                          {errors["form.supervisor_signed_date"] && (
                            <label htmlFor='supervisor_signed_date' className='error_label'>
                              {errors["form.supervisor_signed_date"]}
                            </label>
                          )}
                        </div>
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='disclaimers'>
                  <strong className='diss'>Disclaimer: </strong>You must read and carefully
                  understand the documents that you are filling out and signing. These are legally
                  binding documents and must be accurate. By filling these forms out, you are
                  agreeing to our{" "}
                  <a href='https://firestormfire.com/terms-of-use/' target="blank">Terms of Use, Privacy Policy and Employee Agreements.</a>
                </div>
              </div>
            </div>
            <div className='row form-row last'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='check_radio_group check_disclaimer'>
                    <div className='check_radio_item'>
                      <input
                        type='checkbox'
                        name='disclaimer_check'
                        id='disclaimer_check'
                        field='form.disclaimer_check'
                        checked={form.disclaimer_check}
                        required
                      />
                      <span className='checkbox_checkbox'></span>
                      <label htmlFor='disclaimer_check'>
                        I have confirmed the above details are true and valid.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FormNav
              navigate={this.navigate.bind(this)}
              adminMode={adminMode}
              disclaimer_check={form.disclaimer_check}
              mobileMode={this.props.mobileMode}
            />
            </fieldset>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { docs } = state;
  return { docs, currentUserInfo: getCurrentUserInfo(state) };
}

let connectedEmployeeAgreement = compose(withRouter, connect(mapStateToProps))(EmployeeAgreement);
export { connectedEmployeeAgreement as EmployeeAgreement };
