//export const config = {
// apiUrl: "https://app.firestormfire.com", // production
// s3BucketUrl: "https://firestormfire.sfo2.cdn.digitaloceanspaces.com/",
// assetUrl:
//  "https://firestormfire.sfo2.cdn.digitaloceanspaces.com/live/assets/",
// altImg: "images/no_avatar_profile.jpg",
// baseUrl: "https://app.firestormfire.com/",
// altInvImg: "images/inv_img.jpeg",
// mainUrl: "https://firestormfire.com", // Firestormfire url for application form
// pusherKey: "c960907d773465b9ebb9",
// pusherCluster: "us3",
// dsn: "https://926dccb17d394913a99d10bb627968b0@o432627.ingest.sentry.io/5669506",
//};
export const config = {
   apiUrl: "https://fireapp-dev.wrg-apps.com", // development
   s3BucketUrl: "https://firestormfire-dev.sfo2.cdn.digitaloceanspaces.com/",
   assetUrl: "https://firestormfire-dev.sfo2.cdn.digitaloceanspaces.com/dev/assets/",
   altImg: "images/no_avatar_profile.jpg",
   baseUrl: "https://fireapp-dev.wrg-apps.com/",
   altInvImg: "images/inv_img.jpeg",
   mainUrl: "https://fireapp-dev.wrg-apps.com", // Firestormfire url for application form
   pusherKey: "05da6ba83fa8bbffbeeb",
   pusherCluster: "ap2"
 };
// export const config = {
//   apiUrl: "http://localhost:8000", // development
//   s3BucketUrl: "https://firestormfire.sfo2.cdn.digitaloceanspaces.com/",
//   assetUrl: "https://firestormfire-dev.sfo2.cdn.digitaloceanspaces.com/dev/assets/",
//   altImg: "images/no_avatar_profile.jpg",
//   baseUrl: "http://localhost:8000/",
//   altInvImg: "images/inv_img.jpeg",
//   mainUrl: "https://fireapp.wrg-apps.com",// Firestormfire url for application form,
//   pusherKey: "05da6ba83fa8bbffbeeb",
//   pusherCluster: "ap2"
// };
