export const RehireQuestionComponent = ({
  question,
  fieldName,
  form,
  errors,
  handleInputChange,
  conditionalField,
  conditionalFieldLabel,
  conditionalFieldReasonValue,
  conditionalFieldName,
  conditionalFieldValue,
  required = true,
}) => {
  return (
    <div className="row form-row">
      <p>{question}</p>
      <div className="col">
        <div className="inputs-col">
          <div className="check_radio_group">
            <div className="check_radio_item">
              <input
                type="radio"
                className={`${errors[fieldName] ? "error" : ""}`}
                name={fieldName}
                id={`yes_${fieldName}`}
                value="yes"
                checked={form[fieldName] === "yes"}
                onChange={handleInputChange}
                required={required}
                style={{ cursor: "pointer" }}
              />
              <span className="radio_checkbox"></span>
              <label htmlFor={`yes_${fieldName}`}>
                <strong>Yes</strong>
              </label>
            </div>
            <div className="check_radio_item">
              <input
                type="radio"
                className={`${errors[fieldName] ? "error" : ""}`}
                name={fieldName}
                id={`no_${fieldName}`}
                value="no"
                checked={form[fieldName] === "no"}
                onChange={handleInputChange}
                required={required}
                style={{ cursor: "pointer" }}
              />
              <span className="radio_checkbox"></span>
              <label htmlFor={`no_${fieldName}`}>
                <strong>No</strong>
              </label>
            </div>
          </div>
          {form[fieldName] === conditionalFieldReasonValue &&
            conditionalField && (
              <div className="inputs-items">
                <p className="mt-1">
                  If <strong>{conditionalFieldLabel}</strong>, please explain.
                </p>
                <input
                  type="text"
                  className={`${errors[conditionalFieldName] ? "error" : ""}`}
                  name={conditionalFieldName}
                  id={conditionalFieldName}
                  value={conditionalFieldValue}
                  onChange={handleInputChange}
                  placeholder="-Reason-"
                  required={required}
                />
                {errors[conditionalFieldName] && (
                  <label htmlFor={conditionalFieldName} className="error_label">
                    {errors[conditionalFieldName]}
                  </label>
                )}
              </div>
            )}
        </div>
      </div>
    </div>
  );
};
