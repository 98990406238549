/* eslint-disable eqeqeq */
import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { config } from "_helpers/config";
import _ from "lodash";
import DatePicker from "react-datepicker";
import { docsActions } from "_actions/docs.actions";
import { usPhone } from "_utils/formatter";
import { toast } from "react-toastify";
import { docsService } from "_services/docs.service";
import moment from "moment";
import { SignPad } from "Docs/Components";
import { FormNav } from "./FormNav";
import { navigate, validate } from "./formHandlers";
import { getCurrentUserInfo } from "_helpers/reduxSelector";
import checkIfDocsShouldBeLocked from "_helpers/lockDocs";

class I9 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rehire_doc: false,
      isListAOpen: false,
      isListBOpen: false,
      isListCOpen: false,
      isValid: false,
      user: {
        mailing_address: {},
      },
      form: {
        dob: "",
        signed_date: moment().format("MM/DD/YYYY"),
        translator_signed_date: moment().format("MM/DD/YYYY"),
        work_authorization_expiry:"",
        residential_status: "citizen",
        proof_document: "alien_registration_number",
        translator: "no",
        disclaimer_check: false,
      },
      errors: {},
      form_edited: false,
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleSignature = this.handleSignature.bind(this);
    this.navigate = navigate.bind(this);
    this.validate = validate.bind(this);
    this.isValid = this.isValid.bind(this);
    this.toggleList = this.toggleList.bind(this);
    this.goBack = this.goBack.bind(this);
    // Refs
    this.form = React.createRef();
    this.signContainer = React.createRef();
  }

  toggleList(list) {
    switch (list) {
      case "a":
        this.state.isListAOpen
          ? this.setState({ isListAOpen: false })
          : this.setState({ isListAOpen: true });
        break;
      case "b":
        this.state.isListBOpen
          ? this.setState({ isListBOpen: false })
          : this.setState({ isListBOpen: true });
        break;
      case "c":
        this.state.isListCOpen
          ? this.setState({ isListCOpen: false })
          : this.setState({ isListCOpen: true });
        break;
      default:
        this.setState({
          isListAOpen: false,
          isListBOpen: false,
          isListCOpen: false,
        });
        break;
    }
  }

  handleFormChange(e) {
    if (e.target && e.target.attributes["field"]) {
      let field = e.target.attributes["field"].value;
      let nextState = this.state;
      let { name, value, checked, type } = e.target;
      if (type == "checkbox") {
        _.set(nextState, field, checked);
      } else {
        _.set(nextState, field, value);
      }
      this.setState({
        ...nextState,
        errors: {
          ...this.state.errors,
          [field]: "",
        },
      });
    }
    !this.state.form_edited && this.setState({ form_edited: true });
  }

  handlePhoneChange(e) {
    e.target.value = usPhone(e.target.value);
  }

  handleSignature(field, employee_signature) {
    let nextState = this.state;
    _.set(nextState, field, employee_signature);
    this.setState({
      ...nextState,
      errors: {
        ...this.state.errors,
        [field]: "",
      },
    });
  }

  handleDateChange(field, date) {
    let nextState = this.state;
    if (date){
      _.set(nextState, field, date.format("MM/DD/YYYY"));
    }
    // Will Break Other OnChange if not handled properly.
    else if (!date && field === "form.work_authorization_expiry") {
      _.set(nextState, field, "");
    }
    this.setState({
      ...nextState,
      errors: {
        [field]: "",
      },
    });
  }
  isValid(...args) {
    let valid = true;
    //console.log("VALIDATION", ...args);
    args.forEach((item) => {
      if (!item) {
        valid = false;
      }
    });
    return valid;
  }
  submitForm(e) {
    e.preventDefault();

    let err = this.validate();

    if (err) {
      return;
    }

    let { form, doc } = this.state;

    let data = {
      id: doc.id,
      doc_type: doc.docs_type_id,
      form,
    };

    if (this.props.adminMode) {
      data.admin_mode = this.props.adminMode;
    }
    this.props.single_doc ? (data.single_doc = true) : (data.single_doc = false);
    this.props.rehire ? (data.rehire = true) : (data.rehire = false);

    this.props.dispatch(docsActions.saveDoc(data)).then((res) => {
      if (res.success) {
        if (this.props.mobileMode && !this.props.lastPage) {
          this.props.setScreen("sidebar");
          this.navigate("next", this.props.rehire);
        } else if (this.props.lastPage) {
          if (this.props.adminMode) {
            this.props.history.push(`/docs`);
          } else {
            this.props.history.push(`/dashboard`);
          }
        } else {
          this.navigate("next", this.props.rehire);
        }
      } else {
        if (this.props.mobileMode) this.props.toggleErrorPopup(res.message);
        else toast.error(res.message);
      }
    });
  }

  // GET FORM data from api with id passed in props
  getFormData(id) {
    docsService.getForm(id).then((res) => {
      if (res.success) {
        const currentState = this.state;
        let { user, form } = res.data;
        // date needs to a moment object or Datepicker breaks. this.state.isValid
        form.signed_date = form.signed_date || currentState.form.signed_date;
        form.work_authorization_expiry =
          form.work_authorization_expiry || currentState.form.work_authorization_expiry;
        form.dob = user.dob || form.dob || currentState.form.dob;
        
           //updating data in user object to form object
           form.ssn=user.ssn||currentState.form.ssn;
        form.translator_signed_date =
          form.translator_signed_date || currentState.form.translator_signed_date;
        // spread the data coming from API into state
        this.setState({
          user: {
            ...this.state.user,
            ...user,
          },
          form: {
            ...this.state.form,
            ...form,
          },
        });
      }
    });
  }

  goBack() {
    // this.props.toggle();
    if (this.state.form_edited) {
      this.props.toggleCloseConfirmation(true);
    } else {
      this.props.setScreen("sidebar");
    }
  }

  componentDidMount() {
    let docSlug = this.props.match.params.doc;
    let doc;
    // if (this.props.rehire) {
    //     doc = this.props.docs.userDocs.find(
    //         (doc) => doc.slug === docSlug && doc.docs_categories_id === 2,
    //     );
    // } else {
    //     doc = this.props.docs.userDocs.find((doc) => doc.slug === docSlug && doc.docs_categories_id == 1);
    // }
    if (this.props.giss) {
      if (this.props.rehire) {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id === 5,
        );
      } else {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id == 4,
        );
      }
    } else {
      if (this.props.rehire) {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id === 2,
        );
      } else {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id == 1,
        );
      }
    }
    const height = this.signContainer.clientHeight;
    const width = this.signContainer.clientWidth;
    this.setState({
      doc,
      sign: {
        height: height,
        width: width,
      },
    });
    this.getFormData(doc.id);
  }

  render() {
    let { user, form, errors } = this.state;
    let { adminMode, currentUserInfo } = this.props;

    const shouldLockDocs = checkIfDocsShouldBeLocked(currentUserInfo, form, adminMode)

    return (
      <div className='doc_info-rh'>
        {/* Title */}
        <div className='doc-title'>
          <img src={`${config.assetUrl}icons/doc-title-img.svg`} className='doc-title-img' />
          <span className='close-button' onClick={this.goBack}>
            <img src={`${config.assetUrl}icons/back-btn.svg`} />
          </span>
          <h3>Form I-9 (2024)</h3>
        </div>
        <div className='doc_info-form form-i9'>
          <form
            className='info-form'
            ref={this.form}
            onChange={this.handleFormChange}
            onSubmit={this.submitForm}
            noValidate>
           <fieldset disabled={shouldLockDocs}>
            <div className='row'>
              <div className='col-md-12'>
                <p>
                  <strong>
                    START HERE: Read instructions carefully before completing this form. The
                    instructions must be available, either in paper or electronically, during
                    completion of this form. Employers are liable for errors in the completion of
                    this form.
                  </strong>
                </p>
                <p>
                  <strong>ANTI-DISCRIMINATION NOTICE:</strong> It is illegal to discriminate against
                  work-authorized individuals. Employers CANNOT specify which document(s) an
                  employee may present to establish employment authorization and identity. The
                  refusal to hire or continue to employ an individual because the documentation
                  presented has a future expiration date may also constitute illegal discrimination.
                </p>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col m-0'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    SECTION 1 - Employee Information and Attestation
                  </div>
                  <div className='inputs-head'>
                    Full Name<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items group m-0'>
                    {/*input group */}
                    <div className='grouped'>
                      <input
                        className={`${errors["user.first_name"] ? "error" : ""}`}
                        type='text'
                        name='first_name'
                        id='first_name'
                        placeholder='First Name'
                        value={user.first_name}
                        field='user.first_name'
                        required
                      />
                      {errors["user.first_name"] && (
                        <label htmlFor='first_name' className='error_label'>
                          {errors["user.first_name"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        className={`${errors["user.middle_name"] ? "error" : ""}`}
                        type='text'
                        name='middle_name'
                        id='middle_name'
                        placeholder='Middle Name (optional)'
                        value={user.middle_name}
                        field='user.middle_name'
                      />
                      {errors["user.middle_name"] && (
                        <label htmlFor='middle_name' className='error_label'>
                          {errors["user.middle_name"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        className={`${errors["user.last_name"] ? "error" : ""}`}
                        type='text'
                        name='last_name'
                        id='last_name'
                        placeholder='Last Name'
                        value={user.last_name}
                        field='user.last_name'
                        required
                      />
                      {errors["user.last_name"] && (
                        <label htmlFor='last_name' className='error_label'>
                          {errors["user.last_name"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='inputs-col'>
                  {/*single input */}
                  <div className='inputs-items'>
                    <input
                      className={`${errors["form.other_last_name"] ? "error" : ""}`}
                      type='text'
                      name='other_last_name'
                      id='other_last_name'
                      placeholder='Other Last Name Used(if any)'
                      value={form.other_last_name}
                      field='form.other_last_name'
                    />
                    {errors["form.other_last_name"] && (
                      <label htmlFor='other_last_name' className='error_label'>
                        {errors["form.other_last_name"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className='col m-0'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Mailing Address<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <input
                      className={`${errors["user.mailing_address.address"] ? "error" : ""}`}
                      type='text'
                      name='address'
                      id='address'
                      placeholder='Street or Box Number'
                      value={user.mailing_address.address}
                      field='user.mailing_address.address'
                      required
                    />
                    {errors["user.mailing_address.address"] && (
                      <label htmlFor='address' className='error_label'>
                        {errors["user.mailing_address.address"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-items group'>
                    <div className='grouped'>
                      <input
                        className={`${errors["user.mailing_address.city"] ? "error" : ""}`}
                        type='text'
                        name='city'
                        id='City'
                        placeholder='City'
                        value={user.mailing_address.city}
                        field='user.mailing_address.city'
                        required
                      />
                      {errors["user.mailing_address.city"] && (
                        <label htmlFor='city' className='error_label'>
                          {errors["user.mailing_address.city"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        className={`${errors["user.mailing_address.state"] ? "error" : ""}`}
                        type='text'
                        name='state'
                        id='state'
                        placeholder='State'
                        value={user.mailing_address.state}
                        field='user.mailing_address.state'
                        required
                      />
                      {errors["user.mailing_address.state"] && (
                        <label htmlFor='state' className='error_label'>
                          {errors["user.mailing_address.state"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        className={`${errors["user.mailing_address.zip"] ? "error" : ""}`}
                        type='text'
                        name='zip'
                        id='zip'
                        placeholder='Zip'
                        value={user.mailing_address.zip}
                        field='user.mailing_address.zip'
                        required
                      />
                      {errors["user.mailing_address.zip"] && (
                        <label htmlFor='zip' className='error_label'>
                          {errors["user.mailing_address.zip"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Date of Birth<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <DatePicker
                      className={`${errors["form.dob"] ? "error" : ""} w-100`}
                      name='dob'
                      id='dob'
                      required
                      selected={form.dob ? moment(form.dob) : ""}
                      field='form.dob'
                      placeholder='mm/dd/yyyy'
                      onChange={this.handleDateChange.bind(this, "form.dob")}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode='select'
                      autoComplete='off'
                    />
                    {moment().diff(moment(form.dob, "MM/DD/YYYY"), "year") < 18 && (
                      <label htmlFor='dob' className='error_label'>
                        Invalid DOB date is provided. Age should be greater than 18 years.
                      </label>
                    )}
                    {errors["form.dob"] && (
                      <label htmlFor='dob' className='error_label'>
                        {errors["form.dob"]}
                      </label>
                    )}
                  </div>
                </div>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Social Security Number<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <input
                      className={`${errors["form.ssn"] ? "error" : ""}`}
                      type='number'
                      name='ssn'
                      id='ssn'
                      placeholder='Enter SSN Number Here'
                      value={form.ssn}
                      field='form.ssn'
                      required
                    />
                    {errors["form.ssn"] && (
                      <label htmlFor='ssn' className='error_label'>
                        {errors["form.ssn"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>Employee’s Email Address</div>
                  <div className='inputs-items'>
                    <input
                      className={`${errors["user.email"] ? "error" : ""}`}
                      type='text'
                      name='email'
                      id='email'
                      placeholder='Enter Here'
                      value={user.email}
                      field='user.email'
                      disabled
                    />
                    {errors["user.email"] && (
                      <label htmlFor='email' className='error_label'>
                        {errors["user.email"]}
                      </label>
                    )}
                  </div>
                </div>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Employee’s Telephone Number<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <input
                      className={`${errors["user.phone"] ? "error" : ""}`}
                      type='tel'
                      name='phone'
                      id='phone'
                      placeholder='Phone Number'
                      value={user.phone}
                      field='user.phone'
                      onChange={this.handlePhoneChange}
                      required
                    />
                    {errors["user.phone"] && (
                      <label htmlFor='phone' className='error_label'>
                        {errors["user.phone"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <p>
                    I am aware that federal law provides for imprisonment and/or fines for false
                    statements or use of false documents in connection with the completion of this
                    form.
                  </p>
                  <p>I attest, under penalty of perjury, that I am ,</p>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='radio'
                        name='residential_status'
                        value='citizen'
                        id='citizen'
                        field='form.residential_status'
                        checked={form.residential_status === "citizen"}
                        required
                      />
                      <span className='radio_checkbox'></span>
                      <label htmlFor='citizen'>
                        <strong>A citizen of the United States</strong>
                      </label>
                    </div>
                  </div>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='radio'
                        name='residential_status'
                        value='non_citizen'
                        id='non_citizen'
                        field='form.residential_status'
                        checked={form.residential_status === "non_citizen"}
                        required
                      />
                      <span className='radio_checkbox'></span>
                      <label htmlFor='non_citizen'>
                        <strong>A noncitizen national of the United States</strong>{" "}
                        <span className='notes'>(See instructions)</span>
                      </label>
                    </div>
                  </div>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='radio'
                        name='residential_status'
                        value='permanent_resident'
                        id='permanent_resident'
                        field='form.residential_status'
                        checked={form.residential_status === "permanent_resident"}
                        required
                      />
                      <span className='radio_checkbox'></span>
                      <label htmlFor='permanent_resident'>
                        <strong>A lawful permanent resident</strong>
                      </label>
                    </div>
                    {form.residential_status === "permanent_resident" && (
                      <div className='inputs-col'>
                        <div className='inputs-head'>Alien Registration Number/USCIS Number</div>
                        <div className='inputs-items'>
                          <input
                            className={`${errors["form.alien_registration_number"] ? "error" : ""}`}
                            type='text'
                            name='alien_registration_number'
                            id='alien_registration_number'
                            placeholder='Enter here'
                            value={form.alien_registration_number}
                            field='form.alien_registration_number'
                            required
                          />
                          {errors["form.alien_registration_number"] && (
                            <label htmlFor='alien_registration_number' className='error_label'>
                              {errors["form.alien_registration_number"]}
                            </label>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='radio'
                        name='residential_status'
                        value='authorized_to_work'
                        id='authorized_to_work'
                        field='form.residential_status'
                        checked={form.residential_status === "authorized_to_work"}
                        required
                      />
                      <span className='radio_checkbox'></span>
                      <label htmlFor='authorized_to_work'>
                        <strong>An alien authorized to work</strong>
                      </label>
                    </div>
                    {form.residential_status === "authorized_to_work" && (
                      <div className='inputs-col'>
                        <div className='inputs-head'>
                          Expiration Date <span>(if applicable)</span>
                        </div>
                        <div className='inputs-items'>
                          <DatePicker
                            className={`${
                              errors["form.work_authorization_expiry"] ? "error" : ""
                              } w-100`}
                            name='work_authorization_expiry'
                            id='work_authorization_expiry'
                            // required
                            selected={form.work_authorization_expiry ? moment(form.work_authorization_expiry) : ""}
                            field='form.work_authorization_expiry'
                            placeholder='mm/dd/yyyy'
                            onChange={this.handleDateChange.bind(
                              this,
                              "form.work_authorization_expiry",
                            )}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode='select'
                            autoComplete='off'
                          />
                          {errors["form.work_authorization_expiry"] && (
                            <label htmlFor='work_authorization_expiry' className='error_label'>
                              {errors["form.work_authorization_expiry"]}
                            </label>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col mb-sm-0'>
                  <p>
                    Aliens authorized to work must provide only one of the following document
                    numbers to complete Form I-9
                  </p>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='radio'
                        name='proof_document'
                        value='alien_registration_number'
                        id='alien_registration_number'
                        field='form.proof_document'
                        checked={form.proof_document === "alien_registration_number"}
                        required
                      />
                      <span className='radio_checkbox'></span>
                      <label htmlFor='alien_registration_number'>
                        <strong>Alien Registration Number/USCIS Number</strong>
                      </label>
                    </div>
                    {form.proof_document === "alien_registration_number" && (
                      <div className='inputs-col'>
                        <div className='inputs-items'>
                          <input
                            className={`${errors["form.alien_registration_number"] ? "error" : ""}`}
                            type='text'
                            name='alien_registration_number'
                            id='alien_registration_number'
                            placeholder='Enter here'
                            value={form.alien_registration_number}
                            field='form.alien_registration_number'
                            required
                          />
                          {errors["form.alien_registration_number"] && (
                            <label htmlFor='alien_registration_number' className='error_label'>
                              {errors["form.alien_registration_number"]}
                            </label>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='radio'
                        name='proof_document'
                        value='i94_addmission_number'
                        id='i94_addmission_number'
                        field='form.proof_document'
                        checked={form.proof_document === "i94_addmission_number"}
                        required
                      />
                      <span className='radio_checkbox'></span>
                      <label htmlFor='i94_addmission_number'>
                        <strong>Form I-94 Admission Number</strong>{" "}
                      </label>
                    </div>
                    {form.proof_document === "i94_addmission_number" && (
                      <div className='inputs-col'>
                        <div className='inputs-items'>
                          <input
                            className={`${errors["form.i94_addmission_number"] ? "error" : ""}`}
                            type='text'
                            name='i94_addmission_number'
                            id='i94_addmission_number'
                            placeholder='Enter here'
                            value={form.i94_addmission_number}
                            field='form.i94_addmission_number'
                            required
                          />
                          {errors["form.i94_addmission_number"] && (
                            <label htmlFor='i94_addmission_number' className='error_label'>
                              {errors["form.i94_addmission_number"]}
                            </label>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='col f_passport_check'>
                <div className='inputs-col'>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='radio'
                        name='proof_document'
                        value='foreign_passport'
                        id='foreign_passport'
                        field='form.proof_document'
                        checked={form.proof_document === "foreign_passport"}
                        required
                      />
                      <span className='radio_checkbox'></span>
                      <label htmlFor='foreign_passport'>
                        <strong>Foreign Passport Number</strong>
                      </label>
                    </div>
                    {form.proof_document === "foreign_passport" && (
                      <div className='inputs-col inputs-col-g pr-1'>
                        <div className='inputs-items'>
                          <input
                            className={`${errors["form.foreign_passport_number"] ? "error" : ""}`}
                            type='text'
                            name='foreign_passport_number'
                            id='foreign_passport_number'
                            placeholder='Enter here'
                            value={form.foreign_passport_number}
                            field='form.foreign_passport_number'
                            required
                          />
                          {errors["form.foreign_passport_number"] && (
                            <label htmlFor='foreign_passport_number' className='error_label'>
                              {errors["form.foreign_passport_number"]}
                            </label>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {form.proof_document === "foreign_passport" && (
                  <div className='inputs-col pr-3'>
                    <div className='check_radio_group agreement_checks'>
                      <div className='check_radio_item'>
                        <label htmlFor='foreign_passport' className='ml-1'>
                          <strong>Country of Issuance</strong>
                          <span className='required-star'>&#x2A;</span>
                        </label>
                      </div>
                      <div className='inputs-col p-0'>
                        <div className='inputs-items'>
                          <input
                            className={`${errors["form.foreign_passport_country"] ? "error" : ""}`}
                            type='text'
                            name='foreign_passport_country'
                            id='foreign_passport_country'
                            placeholder='Enter here'
                            value={form.foreign_passport_country}
                            field='form.foreign_passport_country'
                            required
                          />
                          {errors["form.foreign_passport_country"] && (
                            <label htmlFor='foreign_passport_country' className='error_label'>
                              {errors["form.foreign_passport_country"]}
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/*row ends*/}
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>Preparer and/or Translator Certification</div>
                </div>
              </div>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='radio'
                        name='translator'
                        value='no'
                        id='translator_no'
                        field='form.translator'
                        checked={form.translator === "no"}
                      />
                      <span className='radio_checkbox'></span>
                      <label htmlFor='translator_no'>I did not use a preparer or translator.</label>
                    </div>
                    <div className='check_radio_item'>
                      <input
                        type='radio'
                        name='translator'
                        value='yes'
                        id='translator_yes'
                        field='form.translator'
                        checked={form.translator === "yes"}
                      />
                      <span className='radio_checkbox'></span>
                      <label htmlFor='translator_yes'>
                        A preparer(s) and/or translator(s) assisted the employee in completing
                        Section 1
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {form.translator === "yes" && (
                <React.Fragment>
                  <div className='col px-3'>
                    <div className='inputs-col'>
                      <div className='inputs-head'>
                        Full Name<span className='required-star'>&#x2A;</span>
                      </div>
                      <div className='inputs-items group'>
                        {/*input group */}
                        <div className='grouped'>
                          <input
                            className={`${errors["form.translator_first_name"] ? "error" : ""}`}
                            type='text'
                            name='translator_first_name'
                            id='translator_first_name'
                            placeholder='First Name (Given Name)'
                            value={form.translator_first_name}
                            field='form.translator_first_name'
                            required
                          />
                          {errors["form.translator_first_name"] && (
                            <label htmlFor='first_name' className='error_label'>
                              {errors["form.translator_first_name"]}
                            </label>
                          )}
                        </div>
                        <div className='grouped'>
                          <input
                            className={`${errors["form.translator_last_name"] ? "error" : ""}`}
                            type='text'
                            name='translator_last_name'
                            id='translator_last_name'
                            placeholder='Last Name  (Family Name)'
                            value={form.translator_last_name}
                            field='form.translator_last_name'
                            required
                          />
                          {errors["form.translator_last_name"] && (
                            <label htmlFor='last_name' className='error_label'>
                              {errors["form.translator_last_name"]}
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col  px-3 m-0'>
                    <div className='inputs-col m-0'>
                      <div className='inputs-head'>
                        Home Address<span className='required-star'>&#x2A;</span>
                      </div>
                      <div className='inputs-items'>
                        <input
                          className={`${errors["form.translator_address_street"] ? "error" : ""}`}
                          type='text'
                          name='translator_address_street'
                          id='translator_address_street'
                          placeholder='Street or Box Number'
                          value={form.translator_address_street}
                          field='form.translator_address_street'
                          required
                        />
                        {errors["form.translator_address_street"] && (
                          <label htmlFor='address' className='error_label'>
                            {errors["form.translator_address_street"]}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='col  px-3'>
                    <div className='inputs-col'>
                      <div className='inputs-items group'>
                        <div className='grouped'>
                          <input
                            className={`${errors["form.translator_address_city"] ? "error" : ""}`}
                            type='text'
                            name='translator_address_city'
                            id='translator_address_city'
                            placeholder='City'
                            value={form.translator_address_city}
                            field='form.translator_address_city'
                            required
                          />
                          {errors["form.translator_address_city"] && (
                            <label htmlFor='city' className='error_label'>
                              {errors["form.translator_address_city"]}
                            </label>
                          )}
                        </div>
                        <div className='grouped'>
                          <input
                            className={`${errors["form.translator_address_state"] ? "error" : ""}`}
                            type='text'
                            name='translator_address_state'
                            id='translator_address_state'
                            placeholder='State'
                            value={form.translator_address_state}
                            field='form.translator_address_state'
                            required
                          />
                          {errors["form.translator_address_state"] && (
                            <label htmlFor='city' className='error_label'>
                              {errors["form.translator_address_state"]}
                            </label>
                          )}
                        </div>
                        <div className='grouped'>
                          <input
                            className={`${errors["form.translator_address_zip"] ? "error" : ""}`}
                            type='text'
                            name='translator_address_zip'
                            id='translator_address_zip'
                            placeholder='Zip'
                            value={form.translator_address_zip}
                            field='form.translator_address_zip'
                            required
                          />
                          {errors["form.translator_address_zip"] && (
                            <label htmlFor='zip' className='error_label'>
                              {errors["form.translator_address_zip"]}
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col'>
                    <div className='inputs-col'>
                      <div className='inputs-head'>
                        Translator Signature<span className='required-star'>&#x2A;</span>
                      </div>
                      <div className='inputs-items'>
                        <div
                          ref={(element) => {
                            this.signContainer = element;
                          }}
                          className='sign_here'>
                          {this.state.sign && (
                            <Fragment>
                              <SignPad
                                id="i9TransSignPad"
                                height={this.state.sign.height}
                                width={this.state.sign.width}
                                handleSignature={this.handleSignature.bind(
                                  this,
                                  "form.translator_signature",
                                )}
                                signature={form.translator_signature}
                                disabled={adminMode}
                              />
                              <input
                                type='hidden'
                                value={form.translator_signature}
                                name='translator_signature'
                                field='form.translator_signature'
                                required></input>
                              {errors["form.translator_signature"] && (
                                <label htmlFor='translator_signature' className='error_label'>
                                  {errors["form.translator_signature"]}
                                </label>
                              )}
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='inputs-col'>
                      <div className='inputs-head'>
                        Date<span className='required-star'>&#x2A;</span>
                      </div>
                      <div className='inputs-items'>
                        <DatePicker
                          className={`${
                            errors["form.translator_signed_date"] ? "error" : ""
                            } w-100`}
                          name='translator_signed_date'
                          id='translator_signed_date'
                          required
                          selected={moment(form.translator_signed_date)}
                          field='form.translator_signed_date'
                          placeholder='mm/dd/yyyy'
                          onChange={this.handleDateChange.bind(this, "form.translator_signed_date")}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode='select'
                          autoComplete='off'
                        />
                        {errors["form.translator_signed_date"] && (
                          <label htmlFor='translator_signed_date' className='error_label'>
                            {errors["form.translator_signed_date"]}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
            {/*row ends*/}
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Employee Signature<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <div
                      ref={(element) => {
                        this.signContainer = element;
                      }}
                      className='sign_here'>
                      {this.state.sign && (
                        <Fragment>
                          <SignPad
                            id="i9EmpSignPad"
                            height={this.state.sign.height}
                            width={this.state.sign.width}
                            handleSignature={this.handleSignature.bind(
                              this,
                              "form.employee_signature",
                            )}
                            signature={form.employee_signature}
                            disabled={adminMode}
                          />
                          <input
                            type='hidden'
                            value={form.employee_signature}
                            name='employee_signature'
                            field='form.employee_signature'
                            required></input>
                          {errors["form.employee_signature"] && (
                            <label htmlFor='employee_signature' className='error_label'>
                              {errors["form.employee_signature"]}
                            </label>
                          )}
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Date<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <DatePicker
                      className={`${errors["form.signed_date"] ? "error" : ""} w-100`}
                      name='signed_date'
                      id='signed_date'
                      required
                      selected={moment(form.signed_date)}
                      field='form.signed_date'
                      placeholder='mm/dd/yyyy'
                      onChange={this.handleDateChange.bind(this, "form.signed_date")}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode='select'
                      autoComplete='off'
                      disabled={true}
                    />
                    {errors["form.signed_date"] && (
                      <label htmlFor='signed_date' className='error_label'>
                        {errors["form.signed_date"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className='row form-row'>
              <div className='col'>
                <div className='disclaimers'>
                  <strong className='diss'>Disclaimer: </strong>You must read and carefully
                  understand the documents that you are filling out and signing. These are legally
                  binding documents and must be accurate. By filling these forms out, you are
                  agreeing to our{" "}
                  <a href='https://firestormfire.com/terms-of-use/' target="blank">Terms of Use, Privacy Policy and Employee Agreements.</a>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className='row form-row last'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='check_radio_group check_disclaimer'>
                    <div className='check_radio_item'>
                      <input
                        type='checkbox'
                        name='disclaimer_check'
                        id='disclaimer_check'
                        field='form.disclaimer_check'
                        checked={form.disclaimer_check}
                        required
                      />
                      <span className='checkbox_checkbox'></span>
                      <label htmlFor='disclaimer_check'>
                        I have confirmed the above details are true and valid.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <FormNav
              adminMode={adminMode}
              navigate={this.navigate.bind(this)}
              disclaimer_check={this.isValid(
                form.disclaimer_check == true,
                moment().diff(moment(form.dob, "MM/DD/YYYY"), "year") >= 18,
              )}
              mobileMode={this.props.mobileMode}
            />
            </fieldset>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { docs } = state;
  return { docs, currentUserInfo: getCurrentUserInfo(state) };
}

let connectedI9 = compose(withRouter, connect(mapStateToProps))(I9);
export { connectedI9 as I9 };
