/* eslint-disable eqeqeq */
import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { config } from "_helpers/config";
import _ from "lodash";
import DatePicker from "react-datepicker";
import { docsActions } from "_actions/docs.actions";
import { usPhone } from "_utils/formatter";
import { toast } from "react-toastify";
import moment from "moment";
import { SignPad } from "Docs/Components";
import { FormNav } from "./FormNav";
import { navigate, validate } from "./formHandlers";
import checkIfDocsShouldBeLocked from "_helpers/lockDocs";

class F8850 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rehire_doc: false,
      isListAOpen: false,
      isListBOpen: false,
      pages: [1, 2],
      page: 1,
      user: {
        mailing_address: {
          country: "",
        },
      },
      form: {
        signed_date: moment().format("MM/DD/YYYY"),
        info_given_date: moment().format("MM/DD/YYYY"),
        hired_date: moment().format("MM/DD/YYYY"),
        job_offered_date: moment().format("MM/DD/YYYY"),
        job_started_date: moment().format("MM/DD/YYYY"),
        employer_signed_date: moment().format("MM/DD/YYYY"),
        dob: "",
      },
      errors: {},
      form_edited: false,
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.validate = validate.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleSignature = this.handleSignature.bind(this);
    this.navigate = navigate.bind(this);
    this.navigatePage = this.navigatePage.bind(this);
    this.toggleList = this.toggleList.bind(this);
    this.skipHandler = this.skipHandler.bind(this);
    this.goBack = this.goBack.bind(this);
    // Refs
    this.form = React.createRef();
    this.signContainer = React.createRef();
  }

  toggleList(list) {
    switch (list) {
      case "privacy-1":
        this.state.isListAOpen
          ? this.setState({ isListAOpen: false })
          : this.setState({ isListAOpen: true });
        break;
      case "privacy-2":
        this.state.isListBOpen
          ? this.setState({ isListBOpen: false })
          : this.setState({ isListBOpen: true });
        break;
      default:
        this.setState({
          isListOpen: false,
        });
        break;
    }
  }

  handleSignature(field, employee_signature) {
    let nextState = this.state;
    _.set(nextState, field, employee_signature);
    this.setState(
      {
        ...nextState,
        errors: {
          ...this.state.errors,
          [field]: "",
        },
      },
      () => {
        // might need to call some handlers later
      },
    );
  }

  handleDateChange(field, date) {
    let nextState = this.state;
    if (date){
      _.set(nextState, field, date.format("MM/DD/YYYY"));
    }
    this.setState({
      ...nextState,
      errors: {
        [field]: "",
      },
    });
  }

  navigatePage(to) {
    let { page } = this.state;

    switch (to) {
      case "next":
        this.setState({ page: page + 1 });
        return;
      case "prev":
        this.setState({ page: page - 1 });
        return;
      default:
        return;
    }
  }

  handleFormChange(e) {
    // safety check for datepicker
    if (e.target && e.target.attributes["field"]) {
      let field = e.target.attributes["field"].value;
      let nextState = this.state;
      let { value, checked, type } = e.target;

      if (type == "checkbox") {
        _.set(nextState, field, checked);
      } else {
        _.set(nextState, field, value);
      }

      this.setState({
        ...nextState,
        errors: {
          ...this.state.errors,
          [field]: "",
        },
      });
    }
    !this.state.form_edited && this.setState({ form_edited: true });
  }

  handlePhoneChange(e) {
    e.target.value = usPhone(e.target.value);
  }

  skipHandler() {
    let { doc } = this.state;
    let { adminMode } = this.props;
    let data = {
      id: doc.id,
      doc_type: doc.docs_type_id,
      skippedPages: [1, 2],
    };

    if (this.props.adminMode) {
      data.admin_mode = this.props.adminMode;
    }

    this.props.dispatch(docsActions.saveDoc(data)).then((res) => {
      if (res.success) {
        if(this.props.mobileMode && !this.props.lastPage){
          this.props.setScreen("sidebar");
          this.navigate("next", this.props.rehire);
        }else if(this.props.lastPage){
          adminMode
            ? this.props.history.push(`/docs`)
            : this.props.history.push(`/dashboard`)
        }else{
          this.navigate("next", this.props.rehire);
        }
      } else {
        toast.error(res.message);
      }
    });
  }

  submitForm(e) {
    e.preventDefault();

    let err = this.validate();

    if (err) {
      return;
    }

    let { form, doc, user, page, pages } = this.state;
    let { adminMode } = this.props;

    if (adminMode && pages && page < pages.length) {
      this.navigatePage("next");
      return;
    }

    let data = {
      id: doc.id,
      doc_type: doc.docs_type_id,
      form,
      user,
      page,
    };

    if (this.props.adminMode) {
      data.admin_mode = this.props.adminMode;
    }
    this.props.single_doc ? (data.single_doc = true) : (data.single_doc = false);
    this.props.rehire ? (data.rehire = true) : (data.rehire = false);

    this.props.dispatch(docsActions.saveDoc(data)).then((res) => {
      if (res.success) {
        if (this.props.mobileMode && !this.props.lastPage) {
          this.props.setScreen("sidebar");
          this.navigate("next", this.props.rehire);
        } else if (this.props.lastPage) {
          if (this.props.adminMode) {
            this.props.history.push(`/docs`);
          } else {
            this.props.history.push(`/dashboard`);
          }
        } else {
          this.navigate("next", this.props.rehire);
        }
      } else {
        if (this.props.mobileMode) this.props.toggleErrorPopup(res.message);
        else toast.error(res.message);
      }
    });
  }

  // GET FORM data from api with id passed in props
  getFormData(id) {
    this.props.dispatch(docsActions.getForm(id)).then((res) => {
      if (res.success) {
        const currentState = this.state;
        let { user, form } = res.data;
        // date needs to a moment object or Datepicker breaks.
        form.signed_date = form.signed_date || currentState.form.signed_date;
        form.dob = user.dob || currentState.form.dob;
        form.ssn=user.ssn|| currentState.form.ssn;

        form.info_given_date = form.info_given_date || currentState.form.info_given_date;
        form.hired_date = form.hired_date || currentState.form.hired_date;
        form.job_offered_date = form.job_offered_date || currentState.form.job_offered_date;
        form.job_started_date = form.job_started_date || currentState.form.job_started_date;
        form.employer_signed_date =
          form.employer_signed_date || currentState.form.employer_signed_date;

        if (!form?.employee_name) {
          form.employee_name = user?.full_name ?? ""
        }
        // spread the data coming from API into state
        this.setState({
          user,
          form,
        });
      }
    });
  }

  goBack() {
    // this.props.resetForm();
    if (this.state.form_edited) {
      this.props.toggleCloseConfirmation(true);
    } else {
      this.props.setScreen("sidebar");
    }
  }

  componentDidMount() {
    let docSlug = this.props.match.params.doc;
    let doc;
    // if (this.props.rehire) {
    //     doc = this.props.docs.userDocs.find(
    //         (doc) => doc.slug === docSlug && doc.docs_categories_id === 2,
    //     );
    // } else {
    //     doc = this.props.docs.userDocs.find((doc) => doc.slug === docSlug && doc.docs_categories_id == 1);
    // }
    if (this.props.giss) {
      if (this.props.rehire) {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id === 5,
        );
      } else {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id == 4,
        );
      }
    } else {
      if (this.props.rehire) {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id === 2,
        );
      } else {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id == 1,
        );
      }
    }
    const height = this.signContainer.clientHeight;
    const width = this.signContainer.clientWidth;

    this.setState({
      doc,
      sign: {
        height: height,
        width: width,
      },
    });

    this.getFormData(doc.id);
  }

  render() {
    let { user, form, errors, page, pages } = this.state;
    let { adminMode, mobileMode } = this.props;

    const shouldLockDocs = checkIfDocsShouldBeLocked(user, form, adminMode)


    return (
      <div className='doc_info-rh'>
        {/* <div className='doc-title'>
          <img src={`${config.assetUrl}icons/doc-title-img.svg`} className='doc-title-img' alt='' />
          <h3>
            8850 &nbsp;&nbsp;<span className='opt_text'>OPTIONAL</span>
          </h3>
          {adminMode && (
            <div className='doc-page-count'>
              <span className='current_page'>{page}</span>
              <span>of {pages.length}</span>
            </div>
          )}
        </div> */}
        {/* Title */}
        <div className='doc-title'>
          <img src={`${config.assetUrl}icons/doc-title-img.svg`} className='doc-title-img' />
          <span className='close-button' onClick={this.goBack}>
            <img src={`${config.assetUrl}icons/back-btn.svg`} />
          </span>
          <h3>
            8850 &nbsp;&nbsp;<span className='opt_text'>OPTIONAL</span>
          </h3>
          {adminMode && (
            <div className='doc-page-count'>
              <span className='current_page'>{page}</span>
              <span>of {pages.length}</span>
            </div>
          )}
        </div>
        <div className='doc_info-form'>
          {(() => {
            switch (page) {
              case 1:
                return (
                  <form
                    className='info-form'
                    ref={this.form}
                    onChange={this.handleFormChange}
                    onSubmit={this.submitForm}
                    name='candidate_info_page'
                    noValidate>
                  <fieldset disabled={shouldLockDocs}>
                    <div className='row form-row'>
                      <div className='col'>
                        <div className='inputs-col'>
                          {/*single input */}
                          <div className='inputs-head'>
                            Employee Name<span className='required-star'>&#x2A;</span>
                          </div>
                          <div className='inputs-items'>
                            <input
                              className={`${errors["form.employee_name"] ? "error" : ""}`}
                              type='text'
                              name='employee_name'
                              id='employee_name'
                              placeholder='Enter here'
                              value={form.employee_name}
                              field='form.employee_name'
                              required
                            />
                            {errors["form.employee_name"] && (
                              <label htmlFor='employee_name' className='error_label'>
                                {errors["form.employee_name"]}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='col'>
                        <div className='inputs-col'>
                          <div className='inputs-head'>
                            Social Security Number<span className='required-star'>&#x2A;</span>
                          </div>
                          <div className='inputs-items'>
                            <input
                              className={`${errors["form.ssn"] ? "error" : ""}`}
                              type='number'
                              name='ssn'
                              id='ssn'
                              placeholder='Enter Here'
                              value={form.ssn}
                              field='form.ssn'
                              required
                            />
                            {errors["form.ssn"] && (
                              <label htmlFor='ssn' className='error_label'>
                                {errors["form.ssn"]}
                              </label>
                            )}
                          </div>
                        </div>
                        <div className='inputs-col'>
                          <div className='inputs-head'>
                            Telephone Number<span className='required-star'>&#x2A;</span>
                          </div>
                          <div className='inputs-items'>
                            <input
                              className={`${errors["user.phone"] ? "error" : ""}`}
                              type='tel'
                              name='phone'
                              id='phone'
                              placeholder='Phone Number'
                              value={user.phone}
                              field='user.phone'
                              onChange={this.handlePhoneChange}
                              required
                            />
                            {errors["user.phone"] && (
                              <label htmlFor='phone' className='error_label'>
                                {errors["user.phone"]}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='col m-0'>
                        <div className='inputs-col'>
                          <div className='inputs-head'>
                            Mailing Address<span className='required-star'>&#x2A;</span>
                          </div>
                          <div className='inputs-items'>
                            <input
                              className={`${errors["user.mailing_address.address"] ? "error" : ""}`}
                              type='text'
                              name='address'
                              id='address'
                              placeholder='Street or Box Number'
                              value={user.mailing_address.address}
                              field='user.mailing_address.address'
                              required
                            />
                            {errors["user.mailing_address.address"] && (
                              <label htmlFor='address' className='error_label'>
                                {errors["user.mailing_address.address"]}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='col'>
                        <div className='inputs-col'>
                          <div className='inputs-items group two-item'>
                            <div className='grouped'>
                              <input
                                className={`${errors["user.mailing_address.city"] ? "error" : ""}`}
                                type='text'
                                name='city'
                                id='city'
                                placeholder='City'
                                value={user.mailing_address.city}
                                field='user.mailing_address.city'
                                required
                              />
                              {errors["user.mailing_address.city"] && (
                                <label htmlFor='city' className='error_label'>
                                  {errors["user.mailing_address.city"]}
                                </label>
                              )}
                            </div>
                            <div className='grouped'>
                              <input
                                type='text'
                                className={`${errors["user.mailing_address.state"] ? "error" : ""}`}
                                name='state'
                                id='state'
                                value={user.mailing_address.state}
                                field='user.mailing_address.state'
                                required
                              />
                              {errors["user.mailing_address.state"] && (
                                <label htmlFor='state' className='error_label'>
                                  {errors["user.mailing_address.state"]}
                                </label>
                              )}
                            </div>
                            <div className='grouped'>
                              <input
                                className={`${
                                  errors["user.mailing_address.country"] ? "error" : ""
                                  }`}
                                type='text'
                                name='country'
                                id='country'
                                placeholder='Country'
                                value={user.mailing_address.country}
                                field='user.mailing_address.country'
                                required
                              />
                              {errors["user.mailing_address.country"] && (
                                <label htmlFor='country' className='error_label'>
                                  {errors["user.mailing_address.country"]}
                                </label>
                              )}
                            </div>
                            <div className='grouped'>
                              <input
                                className={`${errors["user.mailing_address.zip"] ? "error" : ""}`}
                                type='text'
                                name='zip'
                                id='zip'
                                placeholder='Zip'
                                value={user.mailing_address.zip}
                                field='user.mailing_address.zip'
                                required
                              />
                              {errors["user.mailing_address.zip"] && (
                                <label htmlFor='zip' className='error_label'>
                                  {errors["user.mailing_address.zip"]}
                                </label>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col w-50'>
                        <div className='inputs-col '>
                          <div className='inputs-head'>
                            Date of Birth <span className='required-star'>&#x2A;</span>{" "}
                            <span>(If you are under age 40)</span>
                          </div>
                          <div className='inputs-items'>
                            <DatePicker
                              className={`${errors["form.dob"] ? "error" : ""} w-100`}
                              name='dob'
                              id='dob'
                              required
                              selected={form.dob ? moment(form.dob) : ""}
                              field='form.dob'
                              placeholder='mm/dd/yyyy'
                              onChange={this.handleDateChange.bind(this, "form.dob")}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode='select'
                              autoComplete='off'
                            />
                            {errors["form.dob"] && (
                              <label htmlFor='dob' className='error_label'>
                                {errors["form.dob"]}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*row ends*/}
                    <div className='row form-row'>
                      <div className='col'>
                        <div className='inputs-col'>
                          <div className='check_radio_group agreement_checks'>
                            <div className='check_radio_item'>
                              <input
                                type='checkbox'
                                name='swa_check'
                                id='swa_check'
                                field='form.swa_check'
                                checked={form.swa_check}
                              />
                              <span className='checkbox_checkbox'></span>
                              <label htmlFor='swa_check'>
                                <strong>
                                  Check here if you received a conditional certification from the
                                  state workforce agency (SWA) or a participating local agency for
                                  the work opportunity credit.
                                </strong>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*row ends*/}
                    <div className='row form-row'>
                      <div className='col'>
                        <div className='inputs-col'>
                          <div className='check_radio_group agreement_checks'>
                            <div className='check_radio_item'>
                              <input
                                type='checkbox'
                                name='benifts_check'
                                id='benifts_check'
                                field='form.benifts_check'
                                checked={form.benifts_check}
                              />
                              <span className='checkbox_checkbox'></span>
                              <label htmlFor='benifts_check'>
                                <strong>
                                  Check here if any of the following statements apply to you:
                                </strong>
                              </label>
                            </div>
                            <ol className='checkb_lists'>
                              <li>
                                I am a member of a family that has received assistance from
                                Temporary Assistance for Needy Families (TANF) for any 9 months
                                during the past 18 months
                              </li>
                              <li>
                                I am a veteran and a member of a family that received Supplemental
                                Nutrition Assistance Program (SNAP) benefits (food stamps) for at
                                least a 3-month period during the past 15 months.
                              </li>
                              <li>
                                I was referred here by a rehabilitation agency approved by the
                                state, an employment network under the Ticket to Work program, or
                                the Department of Veterans Affairs.
                              </li>
                              <li>
                                <span>
                                  I am at least age 18 but not age 40 or older and I am a member of
                                  a family that:
                                  <ul className='checkb_lists p-0 m-0'>
                                    <li>
                                      <span>A:</span>Received SNAP benefits (food stamps) for the
                                      past 6 months, or
                                    </li>
                                    <li>
                                      <span>B:</span>Received SNAP benefits (food stamps) for at
                                      least 3 of the past 5 months, but is no longer eligible to
                                      receive them
                                    </li>
                                  </ul>
                                </span>
                              </li>
                              <li>
                                During the past year, I was convicted of a felony or released from
                                prison for a felony.
                              </li>
                              <li>
                                I received supplemental security income (SSI) benefits for any month
                                ending during the past 60 days
                              </li>
                              <li>
                                I am a veteran and I was unemployed for a period or periods totaling
                                at least 4 weeks but less than 6 months during the past year.
                              </li>
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*row ends*/}
                    <div className='row form-row'>
                      <div className='col'>
                        <div className='inputs-col'>
                          <div className='check_radio_group agreement_checks'>
                            <div className='check_radio_item'>
                              <input
                                type='checkbox'
                                name='unemployed_check'
                                id='unemployed_check'
                                field='form.unemployed_check'
                                checked={form.unemployed_check}
                              />
                              <span className='checkbox_checkbox'></span>
                              <label htmlFor='unemployed_check'>
                                <strong>
                                  Check here if you are a veteran and you were unemployed for a
                                  period or periods totaling at least 6 months during the past year.
                                </strong>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*row ends*/}
                    <div className='row form-row'>
                      <div className='col'>
                        <div className='inputs-col'>
                          <div className='check_radio_group agreement_checks'>
                            <div className='check_radio_item'>
                              <input
                                type='checkbox'
                                name='disability_check'
                                id='disability_check'
                                field='form.disability_check'
                                checked={form.disability_check}
                              />
                              <span className='checkbox_checkbox'></span>
                              <label htmlFor='disability_check'>
                                <strong>
                                  Check here if you are a veteran entitled to compensation for a
                                  service-connected disability and you were discharged or released
                                  from active duty in the U.S. Armed Forces during the past year.
                                </strong>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*row ends*/}
                    <div className='row form-row'>
                      <div className='col'>
                        <div className='inputs-col'>
                          <div className='check_radio_group agreement_checks'>
                            <div className='check_radio_item'>
                              <input
                                type='checkbox'
                                name='unemployed_disability_check'
                                id='unemployed_disability_check'
                                field='form.unemployed_disability_check'
                                checked={form.unemployed_disability_check}
                              />
                              <span className='checkbox_checkbox'></span>
                              <label htmlFor='unemployed_disability_check'>
                                <strong>
                                  Check here if you are a veteran entitled to compensation for a
                                  service-connected disability and you were unemployed for a period
                                  or periods totaling at least 6 months during the past year.
                                </strong>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*row ends*/}
                    <div className='row form-row'>
                      <div className='col'>
                        <div className='inputs-col'>
                          <div className='check_radio_group agreement_checks'>
                            <div className='check_radio_item'>
                              <input
                                type='checkbox'
                                name='tanf_payments_check'
                                id='tanf_payments_check'
                                field='form.tanf_payments_check'
                                checked={form.tanf_payments_check}
                              />
                              <span className='checkbox_checkbox'></span>
                              <label htmlFor='tanf_payments_check'>
                                <strong>Check here if you are a member of a family that:</strong>
                              </label>
                            </div>
                            <ol className='checkb_lists'>
                              <li>Received TANF payments for at least the past 18 months, or</li>
                              <li>
                                Received TANF payments for any 18 months beginning after August 5,
                                1997, and the earliest 18-month period beginning after August 5,
                                1997, ended during the past 2 years, or
                              </li>
                              <li>
                                Stopped being eligible for TANF payments during the past 2 years
                                because federal or state law limited the maximum time those payments
                                could be made.
                              </li>
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*row ends*/}
                    <div className='row form-row'>
                      <div className='col'>
                        <div className='inputs-col'>
                          <div className='check_radio_group agreement_checks'>
                            <div className='check_radio_item'>
                              <input
                                type='checkbox'
                                name='unemployment_compensation_check'
                                id='unemployment_compensation_check'
                                field='form.unemployment_compensation_check'
                                checked={form.unemployment_compensation_check}
                              />
                              <span className='checkbox_checkbox'></span>
                              <label htmlFor='unemployment_compensation_check'>
                                <strong>
                                  Check here if you are in a period of unemployment that is at least
                                  27 consecutive weeks and for all or part of that period you
                                  received unemployment compensation.
                                </strong>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*row ends*/}
                    <div className='row form-row'>
                      <div className='col'>
                        <div className='inputs-col'>
                          <div className='inputs-head'>
                            Job Applicant’s Signature<span className='required-star'>&#x2A;</span>
                          </div>
                          <div className='inputs-items'>
                            <div
                              ref={(element) => {
                                this.signContainer = element;
                              }}
                              className='sign_here'>
                              {this.state.sign && (
                                <Fragment>
                                  <SignPad
                                    id="f8850EmpSignPad"
                                    height={this.state.sign.height}
                                    width={this.state.sign.width}
                                    handleSignature={this.handleSignature.bind(
                                      this,
                                      "form.employee_signature",
                                    )}
                                    signature={this.state.form.employee_signature}
                                    disabled={adminMode}
                                  />
                                  <input
                                    type='hidden'
                                    value={this.state.form.employee_signature}
                                    name='employee_signature'
                                    field='form.employee_signature'
                                    required></input>
                                  {errors["form.employee_signature"] && (
                                    <label htmlFor='employee_signature' className='error_label'>
                                      {errors["form.employee_signature"]}
                                    </label>
                                  )}
                                </Fragment>
                              )}
                            </div>
                            {/* <div className="sign_here">
                                                            <div className="sign_canvas">
                                                                <div className="sign_placeholder">- Sign Here -</div>
                                                                <canvas id="sign_here" />
                                                            </div>
                                                            <div className="sign_btn-group">
                                                                add class "accept-sign" to the button to enable
                                                                <button type="submit" className="aplcn-prev-button">Clear</button>
                                                                <button type="submit" className="aplcn-next-btn accept-sign">Click to Accept Signature</button>
                                                            </div>
                                                        </div> */}
                          </div>
                        </div>
                        <div className='inputs-col'>
                          <div className='inputs-head'>
                            Date<span className='required-star'>&#x2A;</span>
                          </div>
                          <div className='inputs-items'>
                            <DatePicker
                              className={`${errors["form.signed_date"] ? "error" : ""} w-100`}
                              name='signed_date'
                              id='signed_date'
                              required
                              selected={moment(form.signed_date)}
                              field='form.signed_date'
                              placeholder='mm/dd/yyyy'
                              onChange={this.handleDateChange.bind(this, "form.signed_date")}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode='select'
                              autoComplete='off'
                              disabled={form.recently_separated_veteran == "no"}
                            />
                            {errors["form.signed_date"] && (
                              <label htmlFor='signed_date' className='error_label'>
                                {errors["form.signed_date"]}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*row ends*/}
                    <div className='row form-row'>
                      <div className='col'>
                        <div className='disclaimers dis-notes'>
                          <div className='inputs-head'>
                            PRIVACY ACT AND PAPERWORK REDUCTION ACT NOTICE
                            {/* <span className={`heading_toggle ${this.isListOpen ? 'open' : 'close'}`} onClick={() => this.toggleList('list')}>{`Open ˅`} </span> */}
                            <button
                              type='button'
                              className={`toggler_btn ${this.state.isListAOpen ? "toggled" : ""}`}
                              onClick={() => this.toggleList("privacy-1")}></button>
                          </div>
                          {this.state.isListAOpen && (
                            <Fragment>
                              <p>Section references are to the Internal Revenue Code</p>
                              <p>
                                Section 51(d)(13) permits a prospective employer to request the
                                applicant to complete this form and give it to the prospective
                                employer. The information will be used by the employer to complete
                                the employer’s federal tax return. Completion of this form is
                                voluntary and may assist members of targeted groups in securing
                                employment. Routine uses of this form include giving it to the state
                                workforce agency (SWA), which will contact appropriate sources to
                                confirm that the applicant is a member of a targeted group. This
                                form may also be given to the Internal Revenue Service for
                                administration of the Internal Revenue laws, to the Department of
                                Justice for civil and criminal litigation, to the Department of
                                Labor for oversight of the certifications performed by the SWA, and
                                to cities, states, and the District of Columbia for use in
                                administering their tax laws. We may also disclose this information
                                to other countries under a tax treaty, to federal and state agencies
                                to enforce federal nontax criminal laws, or to federal law
                                enforcement and intelligence agencies to combat terrorism.
                              </p>
                              <p>
                                You are not required to provide the information requested on a form
                                that is subject to the Paperwork Reduction Act unless the form
                                displays a valid OMB control number. Books or records relating to a
                                form or its instructions must be retained as long as their contents
                                may become material in the administration of any Internal Revenue
                                law. Generally, tax returns and return information are confidential,
                                as required by section 6103.
                              </p>
                              <p>
                                The time needed to complete and file this form will vary depending
                                on individual circumstances. The estimated average time is:
                              </p>
                              <ul>
                                <li>
                                  <strong>Recordkeeping - 6 hr, 27 min</strong>
                                </li>
                                <li>
                                  <strong>Learning about the law or the form - 24 min</strong>
                                </li>
                                <li>
                                  <strong>
                                    Preparing and sending this form to the SWA - 31 min
                                  </strong>
                                </li>
                              </ul>
                              <p>
                                If you have comments concerning the accuracy of these time estimates
                                or suggestions for making this form simpler, we would be happy to
                                hear from you. You can send us comments from www.irs.gov/formspubs.
                                Click on “More Information” and then on “Give us feedback.” Or you
                                can send your comments to:
                              </p>
                              <ul>
                                <li>
                                  <strong>
                                    Internal Revenue Service Tax Forms and Publications
                                  </strong>
                                  <br />
                                  <span>
                                    1111 Constitution Ave. NW, IR-6526, Washington, DC 20224
                                  </span>
                                </li>
                              </ul>
                              <p>
                                Do not send this form to this address. Instead, see When and Where
                                To File in the separate instructions.
                              </p>
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='row form-row'>
                      <div className='col'>
                        <div className='disclaimers'>
                          <strong className='diss'>Disclaimer: </strong>You must read and carefully
                          understand the documents that you are filling out and signing. These are
                          legally binding documents and must be accurate. By filling these forms
                          out, you are agreeing to our{" "}
                          <a href='https://firestormfire.com/terms-of-use/' target="blank">Terms of Use, Privacy Policy and Employee Agreements.</a>
                        </div>
                      </div>
                    </div>
                    {/*row ends*/}
                    <div className='row form-row last'>
                      <div className='col'>
                        <div className='inputs-col'>
                          <div className='check_radio_group check_disclaimer'>
                            <div className='check_radio_item'>
                              <input
                                type='checkbox'
                                name='disclaimer_check'
                                id='disclaimer_check'
                                field='form.disclaimer_check'
                                checked={form.disclaimer_check}
                              />
                              <span className='checkbox_checkbox'></span>
                              <label htmlFor='disclaimer_check'>
                                I have confirmed the above details are true and valid.
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*row ends*/}
                    <FormNav
                      adminMode={adminMode}
                      navigate={this.navigate.bind(this)}
                      skip={true}
                      skipHandler={this.skipHandler.bind(this)}
                      disclaimer_check={form.disclaimer_check}
                      mobileMode={this.props.mobileMode}
                    />
                    </fieldset>
                  </form>
                );
              case 2:
                return (
                  <form
                    className='info-form'
                    ref={this.form}
                    onChange={this.handleFormChange}
                    onSubmit={this.submitForm}
                    name='candidate_info_page'
                    noValidate>
                  <fieldset disabled={shouldLockDocs}>

                    <div className='row form-row'>
                      <div className='col'>
                        <div className='inputs-col'>
                          <div className='inputs-head'>
                            Employer’s Name <span className='required-star'>&#x2A;</span>
                          </div>
                          <div className='inputs-items'>
                            <input
                              type='text'
                              className={`${errors["form.employer_name"] ? "error" : ""}`}
                              name='employer_name'
                              id='employer_name'
                              placeholder='Enter Here'
                              value={form.employer_name}
                              field='form.employer_name'
                              required
                            />
                            {errors["form.employer_name"] && (
                              <label htmlFor='employer_name' className='error_label'>
                                {errors["form.employer_name"]}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='col'>
                        <div className='inputs-col'>
                          <div className='inputs-head'>
                            EIN<span className='required-star'>&#x2A;</span>
                          </div>
                          <div className='inputs-items'>
                            <input
                              type='text'
                              className={`${errors["form.ein"] ? "error" : ""}`}
                              name='ein'
                              id='ein'
                              placeholder='Enter Here'
                              value={form.ein}
                              field='form.ein'
                              required
                            />
                            {errors["form.ein"] && (
                              <label htmlFor='ein' className='error_label'>
                                {errors["form.ein"]}
                              </label>
                            )}
                          </div>
                        </div>
                        <div className='inputs-col'>
                          <div className='inputs-head'>
                            Telephone Number<span className='required-star'>&#x2A;</span>
                          </div>
                          <div className='inputs-items'>
                            <input
                              type='tel'
                              className={`${errors["form.employer_phone"] ? "error" : ""}`}
                              name='employer_phone'
                              id='employer_phone'
                              placeholder='Enter Here'
                              value={form.employer_phone}
                              onChange={this.handlePhoneChange}
                              field='form.employer_phone'
                              required
                            />
                            {errors["form.employer_phone"] && (
                              <label htmlFor='employer_phone' className='error_label'>
                                {errors["form.employer_phone"]}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='col m-0'>
                        <div className='inputs-col m-0'>
                          <div className='inputs-head'>
                            Address<span className='required-star'>&#x2A;</span>
                          </div>
                          <div className='inputs-items'>
                            <input
                              type='text'
                              className={`${errors["form.employer_address_street"] ? "error" : ""}`}
                              name='employer_address_street'
                              id='employer_address_street'
                              value={form.employer_address_street}
                              field='form.employer_address_street'
                              required
                              placeholder='Street Address'
                            />
                            {errors["form.employer_address_street"] && (
                              <label htmlFor='employer_address_street' className='error_label'>
                                {errors["form.employer_address_street"]}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='col'>
                        <div className='inputs-col'>
                          <div className='inputs-items group two-item'>
                            <div className='grouped'>
                              <input
                                type='text'
                                className={`${errors["form.employer_address_city"] ? "error" : ""}`}
                                name='employer_address_city'
                                id='employer_address_city'
                                value={form.employer_address_city}
                                field='form.employer_address_city'
                                required
                                placeholder='City'
                              />
                              {errors["form.employer_address_city"] && (
                                <label htmlFor='employer_address_city' className='error_label'>
                                  {errors["form.employer_address_city"]}
                                </label>
                              )}
                            </div>
                            <div className='grouped'>
                              <input
                                type='text'
                                className={`${
                                  errors["form.employer_address_state"] ? "error" : ""
                                  }`}
                                name='employer_address_state'
                                id='employer_address_state'
                                placeholder='State'
                                value={form.employer_address_state}
                                field='form.employer_address_state'
                                required
                              />
                              {errors["form.employer_address_state"] && (
                                <label htmlFor='employer_address_state' className='error_label'>
                                  {errors["form.employer_address_state"]}
                                </label>
                              )}
                            </div>
                            <div className='grouped'>
                              <input
                                type='text'
                                className={`${
                                  errors["form.employer_address_country"] ? "error" : ""
                                  }`}
                                name='employer_address_country'
                                id='employer_address_country'
                                value={form.employer_address_country}
                                field='form.employer_address_country'
                                required
                                placeholder='Country'
                              />
                              {errors["form.employer_address_country"] && (
                                <label htmlFor='employer_address_country' className='error_label'>
                                  {errors["form.employer_address_country"]}
                                </label>
                              )}
                            </div>
                            <div className='grouped'>
                              <input
                                type='text'
                                className={`${errors["form.employer_address_zip"] ? "error" : ""}`}
                                name='employer_address_zip'
                                id='employer_address_zip'
                                value={form.employer_address_zip}
                                field='form.employer_address_zip'
                                required
                                placeholder='Zip'
                              />
                              {errors["form.employer_address_zip"] && (
                                <label htmlFor='employer_address_zip' className='error_label'>
                                  {errors["form.employer_address_zip"]}
                                </label>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row form-row'>
                      <div className='col'>
                        <div className='inputs-col'>
                          <p className='mb-0'>Person to contact, if different from above</p>
                        </div>
                      </div>
                      <div className='col'>
                        <div className='inputs-col'>
                          <div className='inputs-head'>Name</div>
                          <div className='inputs-items'>
                            <input
                              type='text'
                              className={`${errors["form.contact_person_name"] ? "error" : ""}`}
                              name='contact_person_name'
                              id='contact_person_name'
                              placeholder='Enter Here'
                              value={form.contact_person_name}
                              field='form.contact_person_name'
                            />
                            {errors["form.contact_person_name"] && (
                              <label htmlFor='contact_person_name' className='error_label'>
                                {errors["form.contact_person_name"]}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='col w-50'>
                        <div className='inputs-col'>
                          <div className='inputs-head'>Telephone Number</div>
                          <div className='inputs-items'>
                            <input
                              type='tel'
                              className={`${errors["form.contact_person_phone"] ? "error" : ""}`}
                              name='contact_person_phone'
                              id='contact_person_phone'
                              value={form.contact_person_phone}
                              field='form.contact_person_phone'
                              onChange={this.handlePhoneChange}
                              placeholder='Phone Number'
                            />
                            {errors["form.contact_person_phone"] && (
                              <label htmlFor='contact_person_phone' className='error_label'>
                                {errors["form.contact_person_phone"]}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='col m-0'>
                        <div className='inputs-col m-0'>
                          <div className='inputs-head'>Address</div>
                          <div className='inputs-items'>
                            <input
                              type='text'
                              className={`${errors["form.contact_address_street"] ? "error" : ""}`}
                              name='contact_address_street'
                              id='contact_address_street'
                              value={form.contact_address_street}
                              field='form.contact_address_street'
                              placeholder='Street Address'
                            />
                            {errors["form.contact_address_street"] && (
                              <label htmlFor='contact_address_street' className='error_label'>
                                {errors["form.contact_address_street"]}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='col'>
                        <div className='inputs-col'>
                          <div className='inputs-items group two-item'>
                            <div className='grouped'>
                              <input
                                type='text'
                                className={`${errors["form.contact_address_city"] ? "error" : ""}`}
                                name='contact_address_city'
                                id='contact_address_city'
                                value={form.contact_address_city}
                                field='form.contact_address_city'
                                placeholder='City'
                              />
                              {errors["form.contact_address_city"] && (
                                <label htmlFor='contact_address_city' className='error_label'>
                                  {errors["form.contact_address_city"]}
                                </label>
                              )}
                            </div>
                            <div className='grouped'>
                              <input
                                type='text'
                                className={`${errors["form.contact_address_state"] ? "error" : ""}`}
                                name='contact_address_state'
                                id='contact_address_state'
                                value={form.contact_address_state}
                                field='form.contact_address_state'
                                placeholder='State'
                              />
                              {errors["form.contact_address_state"] && (
                                <label htmlFor='contact_address_state' className='error_label'>
                                  {errors["form.contact_address_state"]}
                                </label>
                              )}
                            </div>
                            <div className='grouped'>
                              <input
                                type='text'
                                className={`${
                                  errors["form.contact_address_country"] ? "error" : ""
                                  }`}
                                name='contact_address_country'
                                id='contact_address_country'
                                value={form.contact_address_country}
                                field='form.contact_address_country'
                                placeholder='Country'
                              />
                              {errors["form.contact_address_country"] && (
                                <label htmlFor='contact_address_country' className='error_label'>
                                  {errors["form.contact_address_country"]}
                                </label>
                              )}
                            </div>
                            <div className='grouped'>
                              <input
                                type='text'
                                className={`${errors["form.contact_address_zip"] ? "error" : ""}`}
                                name='contact_address_zip'
                                id='contact_address_zip'
                                value={form.contact_address_zip}
                                field='form.contact_address_zip'
                                placeholder='Zip'
                              />
                              {errors["form.contact_address_zip"] && (
                                <label htmlFor='contact_address_zip' className='error_label'>
                                  {errors["form.contact_address_zip"]}
                                </label>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row form-row worksheet pb-2'>
                      <div className='col'>
                        <div className='inputs-col'>
                          <div className='inputs-head'>
                            If, based on the individual’s age and home address, he or she is a
                            member of group 4 or 6 (as described under Members of Targeted Groups in
                            the separate instructions), enter that group number(4 or 6)
                          </div>
                          <div className='inputs-items'>
                            <input
                              type='number'
                              className={`${errors["form.group_number"] ? "error" : ""}`}
                              name='group_number'
                              id='group_number'
                              value={form.group_number}
                              field='form.group_number'
                              placeholder='- Enter here -'
                              required
                            />
                            {errors["form.group_number"] && (
                              <label htmlFor='group_number' className='error_label'>
                                {errors["form.group_number"]}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row form-row'>
                      <div className='col'>
                        <div className='inputs-col'>
                          <p className='mb-0'>
                            Date applicant: <span className='required-star'>&#x2A;</span>
                          </p>
                        </div>
                      </div>
                      <div className='col'>
                        <div className='inputs-col'>
                          <div className='inputs-head'>
                            Gave Information <span className='required-star'>&#x2A;</span>
                          </div>
                          <div className='inputs-items'>
                            <DatePicker
                              className={`${errors["form.info_given_date"] ? "error" : ""} w-100`}
                              name='info_given_date'
                              id='info_given_date'
                              required
                              selected={moment(form.info_given_date)}
                              field='form.info_given_date'
                              placeholder='mm/dd/yyyy'
                              onChange={this.handleDateChange.bind(this, "form.info_given_date")}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode='select'
                              autoComplete='off'
                            />
                            {errors["form.info_given_date"] && (
                              <label htmlFor='info_given_date' className='error_label'>
                                {errors["form.info_given_date"]}
                              </label>
                            )}
                          </div>
                        </div>
                        <div className='inputs-col'>
                          <div className='inputs-head'>
                            Was Hired <span className='required-star'>&#x2A;</span>
                          </div>
                          <div className='inputs-items'>
                            <DatePicker
                              className={`${errors["form.hired_date"] ? "error" : ""} w-100`}
                              name='hired_date'
                              id='hired_date'
                              required
                              selected={moment(form.hired_date)}
                              field='form.hired_date'
                              placeholder='mm/dd/yyyy'
                              onChange={this.handleDateChange.bind(this, "form.hired_date")}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode='select'
                              autoComplete='off'
                            />
                            {errors["form.hired_date"] && (
                              <label htmlFor='hired_date' className='error_label'>
                                {errors["form.hired_date"]}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='col'>
                        <div className='inputs-col'>
                          <div className='inputs-head'>
                            Was Offered Job<span className='required-star'>&#x2A;</span>
                          </div>
                          <div className='inputs-items'>
                            <DatePicker
                              className={`${errors["form.job_offered_date"] ? "error" : ""} w-100`}
                              name='job_offered_date'
                              id='job_offered_date'
                              required
                              selected={moment(form.job_offered_date)}
                              field='form.job_offered_date'
                              placeholder='mm/dd/yyyy'
                              onChange={this.handleDateChange.bind(this, "form.job_offered_date")}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode='select'
                              autoComplete='off'
                            />
                            {errors["form.job_offered_date"] && (
                              <label htmlFor='job_offered_date' className='error_label'>
                                {errors["form.job_offered_date"]}
                              </label>
                            )}
                          </div>
                        </div>
                        <div className='inputs-col'>
                          <div className='inputs-head'>
                            Started Job<span className='required-star'>&#x2A;</span>
                          </div>
                          <div className='inputs-items'>
                            <DatePicker
                              className={`${errors["form.job_started_date"] ? "error" : ""} w-100`}
                              name='job_started_date'
                              id='job_started_date'
                              required
                              selected={moment(form.job_started_date)}
                              field='form.job_started_date'
                              placeholder='mm/dd/yyyy'
                              onChange={this.handleDateChange.bind(this, "form.job_started_date")}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode='select'
                              autoComplete='off'
                            />
                            {errors["form.job_started_date"] && (
                              <label htmlFor='job_started_date' className='error_label'>
                                {errors["form.job_started_date"]}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row form-row'>
                      <div className='col'>
                        <div className='disclaimers dis-notes'>
                          {/* <!--add class toggled--> */}
                          <div className='inputs-head'>
                            PRIVACY ACT AND PAPERWORK REDUCTION ACT NOTICE
                            <button
                              type='button'
                              className={`toggler_btn ${this.state.isListBOpen ? "toggled" : ""}`}
                              onClick={() => this.toggleList("privacy-2")}></button>
                          </div>
                          {this.state.isListBOpen && (
                            <Fragment>
                              <p>Section references are to the Internal Revenue Code</p>
                              <p>
                                Section 51(d)(13) permits a prospective employer to request the
                                applicant to complete this form and give it to the prospective
                                employer. The information will be used by the employer to complete
                                the employer’s federal tax return. Completion of this form is
                                voluntary and may assist members of targeted groups in securing
                                employment. Routine uses of this form include giving it to the state
                                workforce agency (SWA), which will contact appropriate sources to
                                confirm that the applicant is a member of a targeted group. This
                                form may also be given to the Internal Revenue Service for
                                administration of the Internal Revenue laws, to the Department of
                                Justice for civil and criminal litigation, to the Department of
                                Labor for oversight of the certifications performed by the SWA, and
                                to cities, states, and the District of Columbia for use in
                                administering their tax laws. We may also disclose this information
                                to other countries under a tax treaty, to federal and state agencies
                                to enforce federal nontax criminal laws, or to federal law
                                enforcement and intelligence agencies to combat terrorism.
                              </p>
                              <p>
                                You are not required to provide the information requested on a form
                                that is subject to the Paperwork Reduction Act unless the form
                                displays a valid OMB control number. Books or records relating to a
                                form or its instructions must be retained as long as their contents
                                may become material in the administration of any Internal Revenue
                                law. Generally, tax returns and return information are confidential,
                                as required by section 6103.
                              </p>
                              <p>
                                The time needed to complete and file this form will vary depending
                                on individual circumstances. The estimated average time is:
                              </p>
                              <ul>
                                <li>
                                  <strong>Recordkeeping - 6 hr, 27 min</strong>
                                </li>
                                <li>
                                  <strong>Learning about the law or the form - 24 min</strong>
                                </li>
                                <li>
                                  <strong>
                                    Preparing and sending this form to the SWA - 31 min
                                  </strong>
                                </li>
                              </ul>

                              <p>
                                If you have comments concerning the accuracy of these time estimates
                                or suggestions for making this form simpler, we would be happy to
                                hear from you. You can send us comments from www.irs.gov/formspubs.
                                Click on “More Information” and then on “Give us feedback.” Or you
                                can send your comments to:
                              </p>
                              <ul>
                                <li>
                                  <strong>
                                    Internal Revenue Service Tax Forms and Publications
                                  </strong>
                                  <br />
                                  <span>
                                    1111 Constitution Ave. NW, IR-6526, Washington, DC 20224
                                  </span>
                                </li>
                              </ul>
                              <p>
                                Do not send this form to this address. Instead, see When and Where
                                To File in the separate instructions.
                              </p>
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='row form-row'>
                      <div className='col'>
                        <p>
                          Under penalties of perjury, I declare that the applicant provided the
                          information on this form on or before the day a job was offered to the
                          applicant and that the information I have furnished is, to the best of my
                          knowledge, true, correct, and complete. Based on the information the job
                          applicant furnished on page 1, I believe the individual is a member of a
                          targeted group. I hereby request a certification that the individual is a
                          member of a targeted group.
                        </p>
                      </div>
                      <div className='col'>
                        <div className='inputs-col'>
                          <div className='inputs-head'>
                            Title<span className='required-star'>&#x2A;</span>
                          </div>
                          <div className='inputs-items'>
                            <input
                              type='text'
                              className={`${errors["form.employer_title"] ? "error" : ""}`}
                              name='employer_title'
                              id='employer_title'
                              value={form.employer_title}
                              field='form.employer_title'
                              placeholder='- Enter here -'
                              required
                            />
                            {errors["form.employer_title"] && (
                              <label htmlFor='employer_title' className='error_label'>
                                {errors["form.employer_title"]}
                              </label>
                            )}
                          </div>
                          <div className='inputs-head'>
                            Employer’s Signature <span className='required-star'>&#x2A;</span>
                          </div>
                          <div className='inputs-items'>
                            <div
                              ref={(element) => {
                                this.signContainer = element;
                              }}
                              className='sign_here'>
                              {this.state.sign && (
                                <Fragment>
                                  <SignPad
                                    id="f8850SupSignPad"
                                    height={this.state.sign.height}
                                    width={this.state.sign.width}
                                    handleSignature={this.handleSignature.bind(
                                      this,
                                      "form.employer_signature",
                                    )}
                                    signature={this.state.form.employer_signature}
                                  />
                                  <input
                                    type='hidden'
                                    value={this.state.form.employer_signature}
                                    name='employer_signature'
                                    field='form.employer_signature'
                                    required></input>
                                  {errors["form.employer_signature"] && (
                                    <label htmlFor='employer_signature' className='error_label'>
                                      {errors["form.employer_signature"]}
                                    </label>
                                  )}
                                </Fragment>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='inputs-col'>
                          <div className='inputs-head'>
                            Date<span className='required-star'>&#x2A;</span>
                          </div>
                          <div className='inputs-items'>
                            <DatePicker
                              className={`${
                                errors["form.employer_signed_date"] ? "error" : ""
                                } w-100`}
                              name='employer_signed_date'
                              id='employer_signed_date'
                              required
                              selected={moment(form.employer_signed_date)}
                              field='form.employer_signed_date'
                              placeholder='mm/dd/yyyy'
                              onChange={this.handleDateChange.bind(
                                this,
                                "form.employer_signed_date",
                              )}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode='select'
                              autoComplete='off'
                            />
                            {errors["form.employer_signed_date"] && (
                              <label htmlFor='employer_signed_date' className='error_label'>
                                {errors["form.employer_signed_date"]}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <FormNav
                      adminMode={adminMode}
                      navigate={this.navigate.bind(this)}
                      skip={true}
                      skipHandler={this.skipHandler.bind(this)}
                      disclaimer_check={form.disclaimer_check}
                      mobileMode={this.props.mobileMode}
                    />
                    </fieldset>
                  </form>
                );
              default:
                return;
            }
          })()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { docs } = state;
  return { docs };
}

let connectedF8850 = compose(withRouter, connect(mapStateToProps))(F8850);
export { connectedF8850 as F8850 };
