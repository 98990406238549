import React, { Fragment, useState, useRef, useEffect } from "react";
import { config } from "_helpers/config";
import "../../assets/styles/docs.scss";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { docsActions, createProfileActions } from "_actions";
import "../../assets/styles/timeOfRequest.css";
import { FormNav } from "./FormNav";
import { SignPad } from "Docs/Components";
import { navigate } from "./formHandlers";
import DatePicker from "react-datepicker";
import moment from "moment";
import { withRouter } from "react-router-dom";
import checkIfDocsShouldBeLocked from "_helpers/lockDocs";

const DATE_FORMAT = "MM/DD/YYYY";
const errors = {};

const I9Rehire = ({
  adminMode,
  mobileMode,
  lastPage,
  rehire,
  setScreen,
  isSingleDoc,
  doc,
  docs,
  resetForm,
  ...props
}) => {
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({
    first_name: "",
    middle_initial: "",
    last_name: "",
    new_first_name: "",
    new_middle_initial: "",
    new_last_name: "",
    date: null,
    doc_title: "",
    doc_number: "",
    expiration_date: "",
    employer_name: "",
    employer_signature: "",
    todays_date: null,
    additional_information: "",
    alternative_examine_check: "",
    disclaimer_check: "",
  });

  const [formOwner, setFormOwner] = useState("");
  const [isDocumentLocked, setDocumentLocked] = useState(false);

  const handleFormChange = (e) => {
    // safety check for datepicker
    if (e.target && e.target.attributes["field"]) {
      const field = e.target.attributes["field"].value;
      const { value, checked, type } = e.target;

      setFormValues((prevFormValues) => {
        const nextFormValues = { ...prevFormValues };

        if (type === "checkbox") {
          nextFormValues[field] = checked;
        } else {
          nextFormValues[field] = value;
        }

        return nextFormValues;
      });
    }
  };

  const handleSignature = (field, signature) => {
    setFormValues((prevFormValues) => {
      const nextFormValues = { ...prevFormValues };
      nextFormValues[field] = signature;

      return nextFormValues;
    });
  };

  const handleDateChange = (field, date) => {
    setFormValues((prevFormValues) => {
      const nextFormValues = { ...prevFormValues };

      if (date) {
        nextFormValues[field] = date.format("MM/DD/YYYY");
      } else {
        nextFormValues[field] = null;
      }

      return {
        ...nextFormValues,
      };
    });
  };

  const skipHandler = () => {
  
    let data = {
      id: doc?.id,
      doc_type: doc?.docs_type_id,
      skippedPages: [1],
    };
  
    if (adminMode) {
      data.admin_mode = adminMode;
    }
  
    if (rehire) {
      data.rehire = true;
    }
  
    dispatch(docsActions.saveDoc(data)).then((res) => {
      if (res.success) {
        if (mobileMode && !lastPage) {
          setScreen("sidebar");
          navigate("next", rehire, props?.giss, {
            props: { docs, adminMode, resetForm, ...props },
          });
        } else if (lastPage) {
          if (adminMode) {
            props.history.push("/docs");
          } else {
            props.history.push("/dashboard");
          }
        } else {
          navigate("next", rehire, props?.giss, {
            props: { docs, adminMode, resetForm, ...props },
          });
        }

      } else {
        if (mobileMode) toast.error(res.message);
        else toast.error(res.message);
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const alternativeExamineCheckValue = formValues.alternative_examine_check ? "1" : "";
    let data = {
      id: doc?.id,
      doc_type: doc?.docs_type_id,
      form: {
        ...formValues,
        alternative_examine_check: alternativeExamineCheckValue
      },
      single_doc: isSingleDoc ? true : false,
      rehire: rehire ? true : false,
    };

    dispatch(docsActions.saveDoc(data)).then((res) => {
      if (res.success) {
        if (mobileMode && !lastPage) {
          setScreen("sidebar");
          navigate("next", rehire, props?.giss, {
            props: { docs, adminMode, resetForm, ...props },
          });
        } else if (lastPage) {
          if (adminMode) {
            props.history.push("/docs");
          } else {
            props.history.push("/dashboard");
          }
        } else {
          navigate("next", rehire, props?.giss, {
            props: { docs, adminMode, resetForm, ...props },
          });
        }
      } else {
        if (mobileMode) toast.error(res.message);
        else toast.error(res.message);
      }
    });
  };


  React.useEffect(() => {
    if (doc) {
      dispatch(docsActions.getForm(doc?.id))
        .then((res) => {
          if (res.success) {
            let formValuesData = res?.data?.form;
            let formOwner = res?.data?.form_owner;
            let userData =res?.data?.user;
            setFormValues(formValuesData);
            setFormOwner(formOwner)
            // Update formValues with alternative_examine_check set based on the condition
            setFormValues(prevFormValues => ({
              ...prevFormValues,
              first_name:userData?.first_name,
              last_name:userData?.last_name,
              middle_initial:userData?.middle_name,
              alternative_examine_check: formValuesData?.alternative_examine_check === "1"
            }));

          }
        })
        .catch((err) => {
          toast.error(err?.message ?? "Error on loading data!", {
            position: "top-right",
            autoClose: 5000,
          });
        });
    }
  }, []);

  useEffect(() => {
    const shouldLockDocs = checkIfDocsShouldBeLocked(formOwner, formValues, adminMode);
    setDocumentLocked(shouldLockDocs);
  }, [formOwner, formValues, adminMode]);

  return (
    <div className="doc_info-rh">
      {/* Title */}
      <div className="doc-title">
        <img
          src={`${config.assetUrl}icons/doc-title-img.svg`}
          className="doc-title-img"
        />
        <span className="close-button" onClick={()=> setScreen("sidebar")}>
          <img src={`${config.assetUrl}icons/back-btn.svg`} />
        </span>
        <h3>I9- Rehire</h3>
      </div>

      {/* Form */}
      <div className="doc_info-form">
        <form
          className="info-form"
          // ref={this.form}
          onChange={handleFormChange}
          onSubmit={handleSubmit}
          name="i9_rehire_page"
          // noValidate
        >
          <fieldset disabled={isDocumentLocked}>
            <div className="row form-row">
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">
                    Full Name
                    <span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items group">
                    <div className="grouped">
                      <input
                        className={`${
                          errors["formValues.first_name"] ? "error" : ""
                        }`}
                        type="text"
                        name="first_name"
                        id="first_name"
                        value={formValues.first_name}
                        field="first_name"
                        placeholder="First Name"
                        required
                      />
                      {errors["formValues.first_name"] && (
                        <label htmlFor="first_name" className="error_label">
                          {errors["formValues.first_name"]}
                        </label>
                      )}
                    </div>
                    <div className="grouped">
                      <input
                        type="text"
                        className={`${
                          errors["formValues.middle_initial"] ? "error" : ""
                        }`}
                        name="middle_initial"
                        id="middle_initial"
                        value={formValues.middle_initial}
                        field="middle_initial"
                        placeholder="Middle Name (Optional)"
                      />
                      {errors["formValues.middle_initial"] && (
                        <label htmlFor="middle_initial" className="error_label">
                          {errors["formValues.middle_initial"]}
                        </label>
                      )}
                    </div>
                    <div className="grouped">
                      <input
                        type="text"
                        className={`${
                          errors["formValues.last_name"] ? "error" : ""
                        }`}
                        name="last_name"
                        id="last_name"
                        value={formValues.last_name}
                        field="last_name"
                        placeholder="Last Name"
                        required
                      />
                      {errors["formValues.last_name"] && (
                        <label htmlFor="last_name" className="error_label">
                          {errors["formValues.last_name"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row form-row">
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">
                    New Name(If Applicable)
                  </div>
                  <div className="inputs-items group">
                    <div className="grouped">
                      <input
                        className={`${
                          errors["formValues.first_name"] ? "error" : ""
                        }`}
                        type="text"
                        name="first_name"
                        id="first_name"
                        value={formValues.new_first_name}
                        field="new_first_name"
                        placeholder="First Name"
                      />
                      {errors["formValues.first_name"] && (
                        <label htmlFor="first_name" className="error_label">
                          {errors["formValues.first_name"]}
                        </label>
                      )}
                    </div>
                    <div className="grouped">
                      <input
                        type="text"
                        className={`${
                          errors["formValues.middle_initial"] ? "error" : ""
                        }`}
                        name="middle_initial"
                        id="middle_initial"
                        value={formValues.new_middle_initial}
                        field="new_middle_initial"
                        placeholder="Middle Name (Optional)"
                      />
                      {errors["formValues.middle_initial"] && (
                        <label htmlFor="middle_initial" className="error_label">
                          {errors["formValues.middle_initial"]}
                        </label>
                      )}
                    </div>
                    <div className="grouped">
                      <input
                        type="text"
                        className={`${
                          errors["formValues.last_name"] ? "error" : ""
                        }`}
                        name="last_name"
                        id="last_name"
                        value={formValues.new_last_name}
                        field="new_last_name"
                        placeholder="Last Name"
                        // required
                      />
                      {errors["formValues.last_name"] && (
                        <label htmlFor="last_name" className="error_label">
                          {errors["formValues.last_name"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row form-row">
              <div className="col">
                <div className="col-md-12" style={{ paddingLeft: 0 }}>
                  <p>
                    <strong>Reverification: </strong> If the employee requires
                    reverification, your employee can choose to present any
                    acceptable List A or List C documentation to show continued
                    employment authorization. Enter the document information in
                    the spaces below.
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">
                    Document Title
                  </div>
                  <div className="inputs-items ">
                    <input
                      type="text"
                      className={`${
                        errors["formValues.doc_title"] ? "error" : ""
                      }`}
                      name="doc_title"
                      id="doc_title"
                      value={formValues.doc_title}
                      field="doc_title"
                      placeholder="- Enter here -"
                    
                    />
                    {errors["formValues.doc_title"] && (
                      <label htmlFor="doc_title" className="error_label">
                        {errors["formValues.doc_title"]}
                      </label>
                    )}
                  </div>
                </div>
                <div className="inputs-col">
                  <div className="inputs-head">
                    Document Number
                   
                  </div>
                  <div className="inputs-items">
                    <div className="grouped">
                      <input
                        type="text"
                        className={`${
                          errors["formValues.doc_number"] ? "error" : ""
                        }`}
                        name="doc_number"
                        id="doc_number"
                        value={formValues.doc_number}
                        field="doc_number"
                        placeholder="- Enter Here -"
                       
                      />
                      {errors["formValues.doc_number"] && (
                        <label htmlFor="doc_number" className="error_label">
                          {errors["formValues.doc_number"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
                <div className="inputs-col">
                  <div className="inputs-head">
                    Expiration Date
                  </div>
                  <div className="inputs-items">
                    <DatePicker
                      className={`${
                        errors["formValues.expiration_date"] ? "error" : ""
                      } w-100`}
                      name="expiration_date"
                      id="expiration_date"
                    
                      selected={
                        formValues.expiration_date
                          ? moment(formValues.expiration_date)
                          : null
                      }
                      onChange={(date) =>
                        handleDateChange("expiration_date", date)
                      }
                      field="expiration_date"
                      placeholder="mm/dd/yyyy"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete="off"
                    />
                    {errors["formValues.dob"] && (
                      <label htmlFor="expiration_date" className="error_label">
                        {errors["formValues.expiration_date"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="col-md-12" style={{ paddingLeft: 0 }}>
                  <p>
                    <strong>
                      I attest, under penalty of perjury, that to the best of my
                      knowledge, this employee is authorized to work in the
                      United States, and if the employee presented
                      documentation, the documentation I examined appears to be
                      genuine and to relate to the individual who presented it{" "}
                    </strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="inputs-col">
                <div className="inputs-head">
                  Employer Signature
                  <span className="required-star">&#x2A;</span>
                </div>
                <div className="inputs-items">
                  <div className="sign_here">
                    <Fragment>
                      <SignPad
                        id="employer_signature"
                        height={214}
                        width={446}
                        signature={formValues?.employer_signature}
                        handleSignature={(employer_signature) =>
                          handleSignature(
                            "employer_signature",
                            employer_signature
                          )
                        }
                        disabled={!adminMode}
                      />
                      <input
                        type="hidden"
                        value={formValues.employer_signature}
                        name="employer_signature"
                        field="employer_signature"
                        required
                      ></input>
                      {errors["formValues.employer_signature"] && (
                        <label
                          htmlFor="employer_signature"
                          className="error_label"
                        >
                          {errors["formValues.employer_signature"]}
                        </label>
                      )}
                    </Fragment>
                  </div>
                </div>
              </div>
              <div className="inputs-col">
                <div className="inputs-head">
                  Date<span className="required-star">&#x2A;</span>
                </div>
                <div className="inputs-items">
                  <DatePicker
                    className={`${
                      errors["formValues.todays_date"] ? "error" : ""
                    } w-100`}
                    name="todays_date"
                    id="todays_date"
                    required
                    selected={
                      formValues.todays_date
                        ? moment(formValues.todays_date)
                        : ""
                    }
                    field="todays_date"
                    placeholder="mm/dd/yyyy"
                    onChange={(date) => handleDateChange("todays_date", date)}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    autoComplete="off"
                    disabled={!adminMode}
                  />
                  {errors["formValues.todays_date"] && (
                    <label htmlFor="todays_date" className="error_label">
                      {errors["formValues.todays_date"]}
                    </label>
                  )}
                </div>
                <div className="inputs-col">
                  <div className="inputs-head">
                    Name of Employer or Authorized Representative
                    <span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <input
                      className={`${
                        errors["formValues.employer_name"] ? "error" : ""
                      }`}
                      type="text"
                      name="employer_name"
                      id="employer_name"
                      value={formValues.employer_name}
                      field="employer_name"
                      placeholder="First Name"
                      required
                      disabled={!adminMode}
                    />
                    {errors["formValues.employer_name"] && (
                      <label htmlFor="employer_name" className="error_label">
                        {errors["formValues.employer_name"]}
                      </label>
                    )}
                  </div>

                  <div className="inputs-head">
                    Additional Information (Initial and date each notation.)
                    <span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <input
                      className={`${
                        errors["formValues.additional_information"]
                          ? "error"
                          : ""
                      }`}
                      type="text"
                      name="additional_information"
                      id="additional_information"
                      value={formValues.additional_information}
                      field="additional_information"
                      placeholder="- Enter Name Here -"
                      required
                      disabled={!adminMode}
                    />
                    {errors["formValues.additional_information"] && (
                      <label
                        htmlFor="additional_information"
                        className="error_label"
                      >
                        {errors["formValues.additional_information"]}
                      </label>
                    )}
                  </div>
                  <div className="col">
                    <div className="inputs-col">
                      <div
                        className="check_radio_group check_disclaimer"
                        style={{ justifyContent: "flex-start" }}
                      >
                        <div className="check_radio_item">
                          <input
                            type="checkbox"
                            name="alternative_examine_check"
                            id="alternative_examine_check"
                            field="alternative_examine_check"
                            checked={formValues.alternative_examine_check}
                          />
                          <span className="checkbox_checkbox"></span>

                          <label htmlFor="disclaimer_check">
                            Check here if you used an alternative procedure
                            authorized by DHS to examine documents.
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}

            <div className="row form-row last">
              <div className="col">
                <div className="inputs-col">
                  <div className="check_radio_group check_disclaimer">
                    <div className="check_radio_item">
                      <input
                        type="checkbox"
                        name="disclaimer_check"
                        id="disclaimer_check"
                        field="disclaimer_check"
                        checked={formValues.disclaimer_check}
                        required
                      />
                      <span className="checkbox_checkbox"></span>

                      <label htmlFor="disclaimer_check">
                        I have confirmed the above details are true and valid.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FormNav
              adminMode={adminMode}
              navigate={navigate}
              disclaimer_check={formValues?.disclaimer_check}
              mobileMode={mobileMode}
              lastPage={lastPage}
            />
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export default withRouter(I9Rehire);
