import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { USER_ROLES } from "_constants";
import { compose } from "redux";
import { config } from "_helpers";

class SidebarUserMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.toggle = this.toggle.bind(this);
  }
  
  toggle(e, doc) {
    e.preventDefault();
    e.stopPropagation();

    // Call necessary functions
    this.props.setScreen("document");
    this.props.toggle();

    // Determine if the user is an admin
    const isAdmin =
      this.props.user.profile.data.is_admin === USER_ROLES.Admin ||
      this.props.user.profile.data.is_admin === USER_ROLES.SuperAdmin;

    // Determine the rehire status for the URL
    const rehireParam = this.props.rehire ? "true" : "false";

    // Construct the navigation route
    const route = isAdmin
      ? `/docs/${doc.hash}/${doc.slug}?rehire=${rehireParam}`
      : `/dashboard/docs/${doc.slug}?rehire=${rehireParam}`;

    // Navigate to the new route
    this.props.history.push(route);
  }
  render() {
    let documents = this.props.docs.userDocs;
    let docSlug = this.props.match.params.doc;
    let activeDoc = documents.find((doc) => doc.slug === docSlug);
    let docs_count = this.props.giss
      ? this.props.docs.userDocs.filter((item) => item.docs_categories_id == 4).length
      : this.props.docs.userDocs.filter((item) => item.docs_categories_id == 1).length;
    let rehire_docs_count = this.props.giss
      ? this.props.docs.userDocs.filter((item) => item.docs_categories_id == 5).length
      : this.props.docs.userDocs.filter((item) => item.docs_categories_id == 2).length;
    let user;
    this.props.user &&
      this.props.user.items &&
      this.props.user.items.data &&
      (user = this.props.user.items.data);

    const onboardingShouldLock = user?.docs_status === 3
    const rehireShouldLock = user?.rehire_docs_status === 3
    const isAdmin = this.props.user.profile.data.is_admin === USER_ROLES.Admin || this.props.user.profile.data.is_admin === USER_ROLES.SuperAdmin;

    return (
      <div className='sidebar-user-mobile'>
        {activeDoc && (
          <section className='custom-container'>
            <div className='title'>
              <h3>
                <span>
                  {!this.props.rehire && "Onboarding Documents "}
                  {this.props.rehire && "Rehire Documents "}
                </span>
                <span className='doc-count'>
                  {this.props.rehire ? rehire_docs_count : docs_count}
                </span>
              </h3>
            </div>
            <div className='list-container'>
              <ul className='list'>
                {documents.map((doc) => {
                  if (this.props.giss) {
                    if (!this.props.rehire && doc.docs_categories_id == 4) {
                      return (
                        <li key={doc.id} onClick={() => {this.props.setScreen('document')}}>
                          <Link
                            className={`${activeDoc.slug === doc.slug ? "doc-active " : ""}${
                              doc.status == 2 ? "doc-complete " : ""
                            }`}
                            to={isAdmin 
                              ? `/docs/${doc.hash}/${doc.slug}?rehire=false` 
                              : `/dashboard/docs/${doc.slug}?rehire=false`} key={doc.id}>
                            <span className='icon'>
                              {/* <i className='fa fa-check'></i> */}
                            </span>
                            <span className="name">{doc.doc_type_name}</span>
                            {doc.admin_edit === 1 && (
                              <button
                                className={`${
                                  doc.slug !== this.props.active
                                    ? "disabled"
                                    : ""
                                } cursor-pointer doc-list-item_edit`}
                                onClick={(e) => this.toggle(e, doc)}
                              >
                                <img
                                  src={`${config.assetUrl}icons/doc-edit.svg`}
                                  alt=""
                                />
                              </button>
                            )}
                            <span className="link">
                              <i className="fa fa-chevron-right"></i>
                            </span>
                          </Link>
                        </li>
                      );
                    } else if (this.props.rehire && doc.docs_categories_id == 5) {
                      return (
                        <li key={doc.id} onClick={() => {this.props.setScreen('document')}}>
                          <Link
                            className={`${activeDoc.slug === doc.slug ? "doc-active " : ""}${
                              doc.status == 2 ? "doc-complete " : ""
                            }`}
                            to={isAdmin 
                              ? `/docs/${doc.hash}/${doc.slug}?rehire=true` : `/dashboard/docs/${doc.slug}?rehire=true`}
                            key={doc.id}>
                            <span className='icon'>
                              {/* <i className='fa fa-check'></i> */}
                            </span>
                            <span className="name">{doc.doc_type_name}</span>
                            {doc.admin_edit === 1 && (
                              <button
                              className={`${
                                doc.slug !== this.props.active ? "disabled" : ""
                              } cursor-pointer doc-list-item_edit`}
                                onClick={(e) => this.toggle(e, doc)}
                              >
                                <img
                                  src={`${config.assetUrl}icons/doc-edit.svg`}
                                  alt=""
                                />
                              </button>
                            )}
                            <span className="link">
                              <i className="fa fa-chevron-right"></i>
                            </span>
                          </Link>
                        </li>
                      );
                    }
                  } else {
                    if (!this.props.rehire && doc.docs_categories_id == 1) {
                      return (
                        <li key={doc.id} onClick={() => {this.props.setScreen('document')}}>
                          <Link
                            className={`${activeDoc.slug === doc.slug ? "doc-active " : ""}${
                              doc.status == 2 ? "doc-complete " : ""
                            }`}
                            to={isAdmin 
                              ? `/docs/${doc.hash}/${doc.slug}?rehire=false`:`/dashboard/docs/${doc.slug}?rehire=false`}
                            key={doc.id}>
                            <span className='icon'>
                              {/* <i className='fa fa-check'></i> */}
                            </span>
                            <span className="name">{doc.doc_type_name}</span>
                            {doc.admin_edit === 1 && (
                              <button
                              className={`${
                                doc.slug !== this.props.active ? "disabled" : ""
                              } cursor-pointer doc-list-item_edit`}
                                onClick={(e) => this.toggle(e, doc)}
                              >
                                <img
                                  src={`${config.assetUrl}icons/doc-edit.svg`}
                                  alt=""
                                />
                              </button>
                            )}
                            <span className="link">
                              <i className="fa fa-chevron-right"></i>
                            </span>
                          </Link>
                        </li>
                      );
                    } else if (this.props.rehire && doc.docs_categories_id == 2) {
                      return (
                        <li key={doc.id} onClick={() => {this.props.setScreen('document')}}>
                          <Link
                            className={`${activeDoc.slug === doc.slug ? "doc-active " : ""}${
                              doc.status == 2 ? "doc-complete " : ""
                            }`}           
                            to={isAdmin 
                              ? `/docs/${doc.hash}/${doc.slug}?rehire=true`:`/dashboard/docs/${doc.slug}?rehire=true`}
                            key={doc.id}>
                            <span className='icon'>
                              {/* <i className='fa fa-check'></i> */}
                            </span>
                            <span className="name">{doc.doc_type_name}</span>
                            {doc.admin_edit === 1 && (
                              <button
                              className={`${
                                doc.slug !== this.props.active ? "disabled" : ""
                              } cursor-pointer doc-list-item_edit`}
                                onClick={(e) => this.toggle(e, doc)}
                              >
                                <img
                                  src={`${config.assetUrl}icons/doc-edit.svg`}
                                  alt=""
                                />
                              </button>
                            )}
                            <span className="link">
                              <i className="fa fa-chevron-right"></i>
                            </span>
                          </Link>
                        </li>
                      );
                    }
                  }
                })}
              </ul>
            </div>
          </section>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { user, docs } = state;
  return { user, docs };
}

const connectedSidebarUserMobile = compose(withRouter, connect(mapStateToProps))(SidebarUserMobile);
export { connectedSidebarUserMobile as SidebarUserMobile };
